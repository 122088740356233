import { Board, toId, toPos } from "../../board";
import { Position, around, equals } from "../../Position";
import { createConnectionMap } from "./algorithm/connectionMap";
import { closedLineNumberTheory } from "./closedLineNumberTheory";
import { AnswerMethods } from "./createAnswerMethods";

export const lineNumberTheory = (board: Board, answer: AnswerMethods) => {
  const connectionMap = createConnectionMap(board, answer);
  const status = closedLineNumberTheory(board, answer, connectionMap);
  if (status !== 'stopped') {
    return status;
  }
  return 'stopped';
};
