import { Board } from "../../board";
import { dirs } from "../../Position";
import { AnswerMethods } from "./createAnswerMethods";

export const addWhiteCellWithZero = (board: Board, answer: AnswerMethods) => {
  const { addWhiteCell, hasBlackCell, forbidden } = answer;
  let isAdvanced = false;
  for (let i = 0, len = board.cell.length; i < len; i++) {
    const dir = board.cell[i].qdir;
    if (dir === -1 || dir === 0) {
      continue;
    }
    const dirDelta = dirs[dir];
    let x = i % board.cols;
    let y = Math.floor(i / board.cols);
    x += dirDelta.x;
    y += dirDelta.y;

    let blackCount = 0;
    while (
      board.inBoard({ x, y }) &&
      board.cell[y * board.cols + x].qdir !== dir
    ) {
      if (hasBlackCell({ x, y })) {
        ++blackCount;
      }
      x += dirDelta.x;
      y += dirDelta.y;
    }
    const nextNum = board.inBoard({ x, y })
      ? board.cell[y * board.cols + x].qnum
      : 0;

    if (board.cell[i].qnum - nextNum === blackCount) {
      x = i % board.cols;
      y = Math.floor(i / board.cols);
      x += dirDelta.x;
      y += dirDelta.y;

      while (
        board.inBoard({ x, y }) &&
        board.cell[y * board.cols + x].qdir !== dir
      ) {
        if (!forbidden({ x, y })) {
          if (blackCount > 0 && nextNum > 0) {
            const flag = addWhiteCell(
              answer,
              { x, y },
              "ヒント引き算&黒マスもうあるの白"
            );
            isAdvanced ||= flag;
          }
          if (blackCount > 0) {
            const flag = addWhiteCell(answer, { x, y }, "黒マスもうあるの白");
            isAdvanced ||= flag;
          } else if (nextNum > 0) {
            const flag = addWhiteCell(
              answer,
              { x, y },
              "ヒント引き算による0の白"
            );
            isAdvanced ||= flag;
          } else {
            const flag = addWhiteCell(answer, { x, y }, "0の白");
            isAdvanced ||= flag;
          }
        }
        x += dirDelta.x;
        y += dirDelta.y;
      }
    }
  }
  return isAdvanced ? "advanced" : "stopped";
};
