import { useCallback, useRef, useState } from "react";
import { Board } from "../board";
import { pathfinder } from "../pathfinder";
import { equals } from "../Position";
import { Answers } from "../yajilin/useYajilinAnswer";
import { BoardView } from "./BoardView";

type BoardController = {
  board: Board;
  answers: Answers;
  solver: {
    run: () => void;
  };
  setRedraw: React.Dispatch<React.SetStateAction<number>>;
};
export const BoardController = (props: BoardController) => {
  const { board, answers, solver, setRedraw } = props;
  const cellSize = 100;
  const boardWidth = board.cols * cellSize;
  const boardHeight = board.rows * cellSize;
  const center = 1000;
  const overRef1 = useRef<any>(null);
  const overRef2 = useRef<any>(null);
  const [cx, setCx] = useState(-1);
  const [cy, setCy] = useState(-1);
  const [lines, setLines] = useState<{ tool: string; points: number[] }[]>([]);
  const isDrawing = useRef(false);

  const {
    addBlackCell,
    addWhiteCell,
    removeBlackCell,
    removeWhiteCell,
    fixedLines,
    hasLine,
    addLine,
    removeLine,
  } = answers;

  const onRightClick = useCallback(
    (x: number, y: number) => {
      if (board.cell[y * board.cols + x].qnum !== -1) {
        const status = solver.run();
        return;
      }
      if (answers.hasWhiteCell({ x, y })) {
        removeWhiteCell({ x, y });
      } else {
        addWhiteCell({ x, y });
      }
    },
    [answers]
  );
  const onClick = useCallback(
    (x: number, y: number) => {
      if (board.cell[y * board.cols + x].qnum !== -1) {
        return;
      }

      setCx(x);
      setCy(y);
      setRedraw((prev: number) => prev + 1);
      if (answers.hasWhiteCell({ x, y })) {
        removeBlackCell({ x, y });
        return;
      }

      const target1 = overRef1.current;
      target1.to({
        opacity: 1,
        points: [
          0.01 * cellSize,
          0.01 * cellSize,
          0.01 * cellSize,
          0.01 * cellSize,
          0.99 * cellSize,
          0.01 * cellSize,
          0.01 * cellSize,
          0.99 * cellSize,
        ],
        duration: 0,
      });
      window.setTimeout(() => {
        addBlackCell({ x, y });
        target1.to({
          points: [
            0.99 * cellSize,
            0.01 * cellSize,
            0.01 * cellSize,
            0.99 * cellSize,
            0.99 * cellSize,
            0.01 * cellSize,
            0.01 * cellSize,
            0.99 * cellSize,
          ],
          duration: 0.15,
        });
      }, 0);
      window.setTimeout(() => {
        target1.to({
          opacity: 0,
          duration: 0,
        });
        const target2 = overRef2.current;
        //
        target2.to({
          points: [
            0.99 * cellSize,
            0.99 * cellSize,
            0.99 * cellSize,
            0.99 * cellSize,
            0.99 * cellSize,
            0.99 * cellSize,
          ],
          duration: 0.15,
        });
      }, 150);
    },
    [answers]
  );

  const handleMouseDown = (e: any) => {
    setCx(-1);
    setCy(-1);
    isDrawing.current = true;
    const displayPos = e.target.getStage().getPointerPosition();
    const precisePoint = [
      (displayPos.x - (center - boardWidth / 2)) / cellSize,
      (displayPos.y - (center - boardHeight / 2)) / cellSize,
    ];
    const pos = {
      x: Math.floor(precisePoint[0]),
      y: Math.floor(precisePoint[1]),
    };
    setLines([
      ...lines,
      {
        tool: hasLine(pos) ? "eraser" : "pen",
        points: [displayPos.x, displayPos.y],
      },
    ]);
  };

  const handleMouseMove = (e: any) => {
    setCx(-1);
    setCy(-1);
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    const preciseFirstPoint = {
      x: (lastLine.points[0] - (center - boardWidth / 2)) / cellSize,
      y: (lastLine.points[1] - (center - boardHeight / 2)) / cellSize,
    };
    const firstPoint = {
      x: Math.floor(preciseFirstPoint.x),
      y: Math.floor(preciseFirstPoint.y),
    };
    const preciseLastPoint = {
      x: (point.x - (center - boardWidth / 2)) / cellSize,
      y: (point.y - (center - boardHeight / 2)) / cellSize,
    };
    const lastPoint = {
      x: Math.floor(preciseLastPoint.x),
      y: Math.floor(preciseLastPoint.y),
    };

    const isEraser = fixedLines.some(
      (line) =>
        line.some((point) => equals(point, firstPoint)) &&
        line.some((point) => equals(point, lastPoint))
    );

    if (isEraser) {
      lastLine.tool = "eraser";
    }

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    setCx(-1);
    setCy(-1);
    const lastLine = lines[lines.length - 1];
    if (!lastLine) {
      return;
    }
    const displayPoints = lastLine.points
      .map((e, i) => {
        return i % 2 === 0 ? [e, lastLine.points[i + 1]] : null;
      })
      .filter((e) => e !== null) as number[][];

    {
      const preciseFirstPoint = [
        (lastLine.points[0] - (center - boardWidth / 2)) / cellSize,
        (lastLine.points[1] - (center - boardHeight / 2)) / cellSize,
      ];
      const firstPoint = {
        x: Math.floor(preciseFirstPoint[0]),
        y: Math.floor(preciseFirstPoint[1]),
      };
      const preciseLastPoint = [
        (lastLine.points[lastLine.points.length - 2] -
          (center - boardWidth / 2)) /
          cellSize,
        (lastLine.points[lastLine.points.length - 1] -
          (center - boardHeight / 2)) /
          cellSize,
      ];
      const lastPoint = {
        x: Math.floor(preciseLastPoint[0]),
        y: Math.floor(preciseLastPoint[1]),
      };

      if (removeLine(firstPoint, lastPoint)) {
        setLines([]);
        isDrawing.current = false;
      }
    }

    const forbidden = (x: number, y: number) => {
      return (
        x < 0 ||
        x >= board.cols ||
        y < 0 ||
        y >= board.rows ||
        answers.hasBlackCell({ x, y }) ||
        board.cell[y * board.cols + x].qnum !== -1
      );
    };

    const forbiddenWithLine = (x: number, y: number) =>
      forbidden(x, y) || hasLine({ x, y });

    const cells = displayPoints.map((e, i, arr) => {
      const precisePoint = [
        (e[0] - (center - boardWidth / 2)) / cellSize,
        (e[1] - (center - boardHeight / 2)) / cellSize,
      ];
      const point = [Math.floor(precisePoint[0]), Math.floor(precisePoint[1])];
      const neighborXPoint = [
        -1 + 2 * Math.round(precisePoint[0]) - Math.floor(precisePoint[0]),
        Math.floor(precisePoint[1]),
      ];
      const neighborYPoint = [
        Math.floor(precisePoint[0]),
        -1 + 2 * Math.round(precisePoint[1]) - Math.floor(precisePoint[1]),
      ];
      const neighborPoint = forbiddenWithLine(
        neighborXPoint[0],
        neighborXPoint[1]
      )
        ? neighborYPoint
        : forbiddenWithLine(neighborYPoint[0], neighborYPoint[1])
        ? neighborXPoint
        : Math.abs((precisePoint[0] % 1) - 0.5) >
          Math.abs((precisePoint[1] % 1) - 0.5)
        ? neighborXPoint
        : neighborYPoint;
      return {
        point,
        neighborPoint,
      };
    });

    const lastPoint = cells[cells.length - 1].point;
    const lastNeighborPoint = cells[cells.length - 1].neighborPoint;
    if (
      forbiddenWithLine(lastPoint[0], lastPoint[1]) &&
      forbiddenWithLine(lastNeighborPoint[0], lastNeighborPoint[1])
    ) {
      setLines([]);
      isDrawing.current = false;
      return;
    }

    let normalizedPoints = [cells[0].point];
    for (let i = 1; i < cells.length; i++) {
      const last = normalizedPoints[normalizedPoints.length - 1] as [
        number,
        number
      ];
      const current = cells[i];
      if (last[0] === current.point[0] && last[1] === current.point[1]) {
        continue;
      }
      if (forbiddenWithLine(current.point[0], current.point[1])) {
        if (
          forbiddenWithLine(current.neighborPoint[0], current.neighborPoint[1])
        ) {
          continue;
        }
        const points = pathfinder(
          last,
          current.neighborPoint as [number, number],
          forbiddenWithLine
        );
        if (points === null) {
          setLines([]);
          isDrawing.current = false;
          return;
        }
        normalizedPoints.push(...points);
      } else {
        const points = pathfinder(
          last,
          current.point as [number, number],
          forbiddenWithLine
        );
        if (points === null) {
          setLines([]);
          isDrawing.current = false;
          return;
        }
        normalizedPoints.push(...points);
      }
    }
    if (forbiddenWithLine(lastNeighborPoint[0], lastNeighborPoint[1])) {
      normalizedPoints.push([lastPoint[0], lastPoint[1]]);
    }

    let flag = true;
    do {
      flag = true;
      const memo = new Map<string, number>();
      for (let i = 0; i < normalizedPoints.length; i++) {
        const point = normalizedPoints[i];
        const key = point[0] + "," + point[1];
        if (memo.has(key)) {
          normalizedPoints.splice(
            memo.get(key) as number,
            i - (memo.get(key) as number)
          );
          flag = false;
          break;
        } else {
          memo.set(key, i);
        }
      }
    } while (!flag);

    if (normalizedPoints.length <= 1) {
      setLines([]);
      isDrawing.current = false;
      return;
    }
    setLines([]);
    addLine(normalizedPoints.map((p) => ({ x: p[0], y: p[1] })));
    isDrawing.current = false;
  };

  return (
    <BoardView
      center={center}
      board={board}
      answers={answers}
      boardWidth={boardWidth}
      boardHeight={boardHeight}
      cellSize={cellSize}
      cx={cx}
      cy={cy}
      handleMouseDown={handleMouseDown}
      handleMouseUp={handleMouseUp}
      handleMouseMove={handleMouseMove}
      onClick={onClick}
      onRightClick={onRightClick}
      overRef1={overRef1}
      overRef2={overRef2}
      lines={lines}
    />
  );
};
