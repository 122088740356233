import { Board } from "../puzzle/board";

const include = (ca: string, bottom: string, up: string) => {
  return bottom <= ca && ca <= up;
};

export const decodeArrowNumber16 = (bstr: string, board: Board) => {
  if (bstr === "" || bstr === undefined) {
    return null;
  }
  var c = 0,
    i = 0,
    bd = board;
  for (i = 0; i < bstr.length; i++) {
    var ca = bstr.charAt(i),
      cell = bd.cell[c];
    if (include(ca, "0", "4")) {
      var ca1 = bstr.charAt(i + 1);
      cell.qdir = parseInt(ca, 16);
      cell.qnum = ca1 !== "." ? parseInt(ca1, 16) : -2;
      i++;
    } else if (include(ca, "5", "9")) {
      cell.qdir = parseInt(ca, 16) - 5;
      cell.qnum = parseInt(bstr.substring(i + 1, i + 3), 16);
      i += 2;
    } else if (ca === "-") {
      cell.qdir = parseInt(bstr.substring(i + 1, i + 2), 16);
      cell.qnum = parseInt(bstr.substring(i + 2, i + 5), 16);
      i += 4;
    } else if (ca >= "a" && ca <= "z") {
      c += parseInt(ca, 36) - 10;
    }

    c++;
    if (!bd.cell[c]) {
      break;
    }
  }
  return bstr.substring(i + 1);
};
