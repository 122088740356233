import { useState } from "react";
import { UnionFind } from "./solver/algorithm/unionfind";
import { toId, toPos } from "../board";
import { around, equals, Position } from "../Position";
import { AnswerModel, LineState } from "./answerModel";

export const normalizeLine = (
  lineEdge: Map<number, number>,
  innerLine: Set<number>,
  whiteCells: Set<number>,
  fixedLines: Position[][],
  newLine: Position[],
  edge?: Map<number, number>
) => {
  const newEnd1 = toId(newLine[0]);
  const newEnd2 = toId(newLine[newLine.length - 1]);
  const prevEnd1 = edge?.get(newEnd1) ?? 0;
  const prevEnd2 = edge?.get(newEnd2) ?? 0;

  const index1 = lineEdge.get(toId(newLine[0]));
  const index2 = lineEdge.get(toId(newLine[newLine.length - 1]));
  if (index1 !== undefined && index2 !== undefined && index1 !== index2) {
    const connected1 = fixedLines[index1];
    const connected2 = fixedLines[index2];
    fixedLines[index1] =
      newLine.length > 2
        ? [
            ...(equals(connected1[0], newLine[0])
              ? connected1.reverse()
              : connected1),
            ...newLine.slice(1, newLine.length - 1),
            ...(equals(connected2[0], newLine[newLine.length - 1])
              ? connected2
              : connected2.reverse()),
          ]
        : [
            ...(equals(connected1[0], newLine[0])
              ? connected1.reverse()
              : connected1),
            ...(equals(connected2[0], newLine[newLine.length - 1])
              ? connected2
              : connected2.reverse()),
          ];
    fixedLines.splice(index2, 1);
    newLine.forEach((pos, i) => {
      if (i === 0 || i === newLine.length - 1) {
        lineEdge.delete(toId(pos));
        edge?.delete(toId(pos));
      }
      innerLine.add(toId(pos));
      whiteCells.delete(toId(pos));
    });
    lineEdge.set(toId(connected2[connected2.length - 1]), index1);
    edge?.set(prevEnd1, prevEnd2);
    edge?.set(prevEnd2, prevEnd1);
    for (let i = index2; i < fixedLines.length; ++i) {
      lineEdge.set(toId(fixedLines[i][0]), i);
      lineEdge.set(toId(fixedLines[i][fixedLines[i].length - 1]), i);
    }
  } else if (index1 !== undefined) {
    const connected = fixedLines[index1];
    if (equals(connected[0], newLine[0])) {
      fixedLines[index1] = [...connected.slice(1).reverse(), ...newLine];
    } else {
      fixedLines[index1] = [
        ...connected.slice(0, connected.length - 1),
        ...newLine,
      ];
    }
    newLine.forEach((pos, i) => {
      if (i === newLine.length - 1) {
        lineEdge.set(toId(pos), index1);
      } else {
        innerLine.add(toId(pos));
      }
      whiteCells.delete(toId(pos));
    });
    lineEdge.delete(toId(newLine[0]));
    edge?.delete(newEnd1);
    edge?.set(newEnd2, prevEnd1);
    edge?.set(prevEnd1, newEnd2);
    if (index2 !== undefined) {
      lineEdge.delete(toId(newLine[newLine.length - 1]));
      innerLine.add(toId(newLine[newLine.length - 1]));
    }
  } else if (index2 !== undefined) {
    edge?.delete(newEnd2);
    const connected = fixedLines[index2];
    if (equals(connected[0], newLine[newLine.length - 1])) {
      fixedLines[index2] = [...newLine, ...connected.slice(1)];
    } else {
      fixedLines[index2] = [
        ...newLine,
        ...connected.slice(0, connected.length - 1).reverse(),
      ];
    }
    newLine.forEach((pos, i) => {
      if (i === 0) {
        lineEdge.set(toId(pos), index2);
      } else {
        innerLine.add(toId(pos));
      }
      whiteCells.delete(toId(pos));
    });
    lineEdge.delete(toId(newLine[newLine.length - 1]));
    edge?.set(newEnd1, prevEnd2);
    edge?.set(prevEnd2, newEnd1);
    edge?.delete(newEnd2);
  } else {
    fixedLines.push(newLine);
    newLine.forEach((pos, i) => {
      if (i === 0 || i === newLine.length - 1) {
        lineEdge.set(toId(pos), fixedLines.length - 1);
      } else {
        innerLine.add(toId(pos));
      }
      whiteCells.delete(toId(pos));
    });
    edge?.set(newEnd1, newEnd2);
    edge?.set(newEnd2, newEnd1);
  }
  return { edge, fixedLines, lineEdge, innerLine, whiteCells };
};

export type Answers = {
  addBlackCell: (pos: Position) => void;
  addWhiteCell: (pos: Position) => void;
  removeBlackCell: (pos: Position) => void;
  removeWhiteCell: (pos: Position) => void;
  fixedLines: Position[][];
  hasBlackCell: (pos: Position) => boolean;
  hasWhiteCell: (pos: Position) => boolean;
  hasLine: (pos: Position) => boolean;
  isLineEdge: (pos: Position) => boolean;
  isSameLine: (pos1: Position, pos2: Position) => boolean;
  addLine: (normalizedPoints: Position[]) => void;
  removeLine: (firstPoint: Position, lastPoint: Position) => true | false;
  isSingleLine: () => boolean;
  getBlackCells: () => Position[];
  getWhiteCells: () => Position[];
  clear: () => void;
  getAnswer: () => AnswerModel;
  setAnswer: (answer: AnswerModel) => void;
  horizontal: Map<number, LineState>;
  vertical: Map<number, LineState>;
};
export const useYajilinAnswer = (): Answers => {
  const [blackCells, setBlackCells] = useState<Set<number>>(new Set());
  const [whiteCells, setWhiteCells] = useState<Set<number>>(new Set());
  const [fixedLines, setFixedLines] = useState<Position[][]>([]);
  const [edge, setEdge] = useState<Map<number, number>>(new Map());
  const [lineEdge, setLineEdge] = useState<Map<number, number>>(new Map());
  const [innerLine, setInnerLine] = useState<Set<number>>(new Set());
  const [horizontal, setHorizontal] = useState<Map<number, LineState>>(
    new Map()
  );
  const [vertical, setVertical] = useState<Map<number, LineState>>(new Map());

  const clear = () => {
    setBlackCells(new Set());
    setWhiteCells(new Set());
    setFixedLines([]);
    setEdge(new Map());
    setLineEdge(new Map());
    setInnerLine(new Set());
    setHorizontal(new Map());
    setVertical(new Map());
  };
  const isLoop = (line: Position[]) =>
    line.length > 2 &&
    line[0].x === line[line.length - 1].x &&
    line[0].y === line[line.length - 1].y;

  const hasLine = (pos: Position) =>
    fixedLines.some((line) =>
      (isLoop(line)
        ? line
        : line.filter((_, i) => i !== 0 && i !== line.length - 1)
      ).some((point) => equals(point, pos))
    );

  const isLineEdge = (pos: Position) =>
    fixedLines
      .filter((line) => !isLoop(line))
      .some((line) =>
        line
          .filter((_, i) => i === 0 || i === line.length - 1)
          .some((point) => equals(point, pos))
      );

  const hasLineWithEnd = (pos: Position) =>
    fixedLines.some((line) => line.some((point) => equals(point, pos)));
  const isSameLine = (pos1: Position, pos2: Position) =>
    fixedLines.some(
      (line) =>
        line.some((point) => equals(point, pos1)) &&
        line.some((point) => equals(point, pos2))
    );

  const isSingleLine = () => {
    return (
      fixedLines.length === 1 &&
      [...whiteCells].filter((id) => {
        return (
          !hasLineWithEnd(toPos(id)) &&
          !around(toPos(id)).some(({ pos }) => isLineEdge(pos))
        );
      }).length === 0
    );
  };

  const addBlackCell = (pos: Position) => {
    setBlackCells((prev) => {
      if (prev.has(toId(pos))) {
        return prev;
      }
      const newSet = new Set(prev);
      newSet.add(toId(pos));
      return newSet;
    });
    setWhiteCells((prev) => {
      const newSet = new Set(prev);
      newSet.delete(toId(pos));
      return newSet;
    });
  };

  const hasBlackCell = (pos: Position) => blackCells.has(toId(pos));

  const addWhiteCell = (pos: Position) => {
    setBlackCells((prev) => {
      const newSet = new Set(prev);
      newSet.delete(toId(pos));
      return newSet;
    });
    setWhiteCells((prev) => {
      if (prev.has(toId(pos))) {
        return prev;
      }
      const newSet = new Set(prev);
      newSet.add(toId(pos));
      return newSet;
    });
  };

  const hasWhiteCell = (pos: Position) => whiteCells.has(toId(pos));

  const getBlackCells = () => [...blackCells].map(toPos);
  const getWhiteCells = () => [...whiteCells].map(toPos);
  const removeBlackCell = (pos: Position) => {
    setBlackCells((prev) => {
      const newSet = new Set(prev);
      newSet.delete(toId(pos));
      return newSet;
    });
  };

  const removeWhiteCell = (pos: Position) => {
    setWhiteCells((prev) => {
      const newSet = new Set(prev);
      newSet.delete(toId(pos));
      return newSet;
    });
  };

  const addLine = (line: Position[]) => {
    line.reduce((prev, cur) => {
      if (prev.x === cur.x) {
        setVertical((p) => {
          const newMap = new Map(p);
          newMap.set(toId(prev.y < cur.y ? prev : cur), LineState.Line);
          return newMap;
        });
      }
      if (prev.y === cur.y) {
        setHorizontal((p) => {
          const newMap = new Map(p);
          newMap.set(toId(prev.x < cur.x ? prev : cur), LineState.Line);
          return newMap;
        });
      }
      return cur;
    });

    const next = normalizeLine(
      lineEdge,
      innerLine,
      whiteCells,
      fixedLines,
      line,
      edge
    );
    setLineEdge(new Map(next.lineEdge));
    setInnerLine(new Set(next.innerLine));
    setWhiteCells(new Set(next.whiteCells));
    setEdge(new Map(next.edge));
    setFixedLines(fixedLines.concat());
  };

  const removeLine = (firstPoint: Position, lastPoint: Position) => {
    const eraseLines = fixedLines.filter(
      (line) =>
        line.some((point) => equals(point, firstPoint)) &&
        line.some((point) => equals(point, lastPoint))
    );

    if (eraseLines.length > 0) {
      const eraseLine = eraseLines[0];
      const firstIndex = eraseLine.findIndex((point) =>
        equals(point, firstPoint)
      );
      const lastIndex = eraseLine.findIndex((point) =>
        equals(point, lastPoint)
      );
      if (
        (firstIndex === 0 || lastIndex === 0) &&
        (firstIndex === eraseLine.length - 1 ||
          lastIndex === eraseLine.length - 1) &&
        eraseLine.length >= 8
      ) {
      } else if (firstIndex === 0 || lastIndex === 0) {
        eraseLine.splice(
          Math.min(firstIndex, lastIndex),
          Math.abs(firstIndex - lastIndex)
        );
        if (eraseLine.length <= 1) {
          setFixedLines(fixedLines.filter((line) => line !== eraseLine));
        }

        return true;
      } else if (
        firstIndex === eraseLine.length - 1 ||
        lastIndex === eraseLine.length - 1
      ) {
        eraseLine.splice(
          Math.min(firstIndex, lastIndex) + 1,
          Math.abs(firstIndex - lastIndex)
        );
        if (eraseLine.length <= 1) {
          setFixedLines(fixedLines.filter((line) => line !== eraseLine));
        }
        return true;
      } else {
        // 線の途中での除去
        return true;
      }
    }

    return false;
  };
  const getAnswer = (): AnswerModel => {
    const _vertical = new Map<number, LineState>(vertical);
    const _horizontal = new Map<number, LineState>(horizontal);

    const lineUf = new UnionFind();
    fixedLines.forEach((line) => {
      line.reduce((prev, cur) => {
        lineUf.connect(toId(prev), toId(cur));
        return cur;
      });
    });

    return {
      blackCells: new Set(blackCells),
      whiteCells: new Set(whiteCells),
      edge,
      innerLine,
      lineUf,
      vertical: _vertical,
      horizontal: _horizontal,
      hasLoop: false,
    };
  };

  const setAnswer = (answer: AnswerModel) => {
    setFixedLines((prev) => []);
    setLineEdge(new Map());
    setBlackCells(answer.blackCells);
    setWhiteCells(answer.whiteCells);
    setEdge(answer.edge);
    setHorizontal(answer.horizontal);
    setVertical(answer.vertical);

    let _whiteCells = answer.whiteCells;
    let _fixedLines: Position[][] = [];
    let _lineEdge = new Map<number, number>();
    let _innerLine = answer.innerLine;

    [...answer.horizontal].forEach(([key, value]) => {
      if (value === LineState.Line) {
        const pos = toPos(key);
        const next = normalizeLine(
          _lineEdge,
          _innerLine,
          _whiteCells,
          _fixedLines,
          [pos, { x: pos.x + 1, y: pos.y }]
        );
        _fixedLines = next.fixedLines;
        _lineEdge = next.lineEdge;
        _whiteCells = next.whiteCells;
        _innerLine = next.innerLine;
        setInnerLine(new Set(next.innerLine));
        setWhiteCells(new Set(next.whiteCells));
      }
    });
    [...answer.vertical].forEach(([key, value]) => {
      if (value === LineState.Line) {
        const pos = toPos(key);
        const next = normalizeLine(
          _lineEdge,
          _innerLine,
          _whiteCells,
          _fixedLines,
          [pos, { x: pos.x, y: pos.y + 1 }]
        );
        _fixedLines = next.fixedLines;
        _lineEdge = next.lineEdge;
        _whiteCells = next.whiteCells;
        _innerLine = next.innerLine;
      }
    });
    setFixedLines(_fixedLines);
    setLineEdge(new Map(_lineEdge));
    setWhiteCells(new Set(_whiteCells));
    setInnerLine(new Set(_innerLine));

    // console.log(answer.horizontal, answer.vertical, _fixedLines);
  };

  return {
    addBlackCell,
    addWhiteCell,
    hasBlackCell,
    hasWhiteCell,
    removeBlackCell,
    removeWhiteCell,
    fixedLines,
    hasLine,
    isLineEdge,
    isSameLine,
    addLine,
    removeLine,
    isSingleLine,
    getBlackCells,
    getWhiteCells,
    clear,
    getAnswer,
    setAnswer,
    horizontal,
    vertical,
  };
};
