import { Board } from "../puzzle/board";

export const encodeArrowNumber16 = (outbstr: string, board: Board) => {
  var cm = "",
    count = 0,
    bd = board;
  for (var c = 0; c < bd.cell.length; c++) {
    var pstr = "",
      dir = bd.cell[c].qdir,
      qn = bd.cell[c].qnum;
    if (qn === -2) {
      pstr = dir + ".";
    } else if (qn >= 0 && qn < 16) {
      pstr = dir + qn.toString(16);
    } else if (qn >= 16 && qn < 256) {
      pstr = dir + 5 + qn.toString(16);
    } else if (qn >= 256 && qn < 4096) {
      pstr = "-" + dir + qn.toString(16);
    } else {
      count++;
    }

    if (count === 0) {
      cm += pstr;
    } else if (pstr || count === 26) {
      cm += (count + 9).toString(36) + pstr;
      count = 0;
    }
  }
  if (count > 0) {
    cm += (count + 9).toString(36);
  }

  outbstr += cm;
  return outbstr;
};
