import { Position, around } from "../../Position";
import { AnswerMethods } from "./createAnswerMethods";

export const addWhiteAroundSingleBlack = (
  answer: AnswerMethods,
  black: Position
) => {
  const { addWhiteCell, hasBlackCell, forbidden } = answer;
  let isAdvanced = false;
  let broken = false;
  around(black).forEach(({ pos }) => {
    if (!forbidden(pos)) {
      const flag = addWhiteCell(answer, pos, "黒の周りの白");
      isAdvanced ||= flag;
    } else if (hasBlackCell(pos)) {
      broken = true;
    }
  });
  if (broken) {
    return "broken";
  }
  return isAdvanced ? "advanced" : "stopped";
};
