import "./App.css";
import { Yajilin } from "./components/Yajilin";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Gridkit } from "./puzzle/gridkit/Gridkit";
import { AQAlice } from "./components/AQAlice";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/p/*" element={<Yajilin />} />
        <Route path="/tool/*" element={<Gridkit />} />
        <Route path="/aq-alice" element={<AQAlice />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
