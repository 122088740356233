import { Board, Cell, createBoard } from "../../board";
import { GeneratorConfig } from "./generatorConfig";

let prevBoard = createBoard(10, 10);
let prevScore = 0;
let counter = 0;
export const generateYajilinBoard = (
  lastBoard: Board,
  score: number,
  config: GeneratorConfig
) => {
  if (score >= prevScore * 0.8) {
    prevBoard = lastBoard;
    prevBoard.cell = prevBoard.cell.concat();
    prevScore = score;
  }

  let board = createBoard(10, 10);
  if (
    (score <= 24 && prevScore <= 24) ||
    (prevScore <= 48 && score <= 48 && counter >= config.maxTry / 5) ||
    (prevScore <= 66 && score <= 66 && counter >= (config.maxTry / 5) * 3) ||
    counter >= config.maxTry
  ) {
    const clueCount = config.symmetry ? config.clueCount / 2 : config.clueCount;
    for (let i = 0; i < clueCount; ++i) {
      const x = (Math.random() * board.cols) | 0;
      const y = (Math.random() * board.rows) | 0;
      if (board.cell[y * board.cols + x].qdir !== -1) {
        --i;
        continue;
      }
      {
        const dir = ((Math.random() * 4) | 0) + 1;
        const num =
          i >= config.initialNumber / (config.symmetry ? 2 : 1)
            ? -2
            : config.numbers[
                ((Math.random() * (config.maxNumber - config.minNumber + 1)) |
                  0) +
                  config.minNumber
              ];
        // const num = config.minNumber;
        board.cell[y * board.cols + x].qdir = dir;
        board.cell[y * board.cols + x].qnum = num;
      }
      if (config.symmetry) {
        const dir = ((Math.random() * 4) | 0) + 1;

        const num =
          i >= config.initialNumber / (config.symmetry ? 2 : 1)
            ? -2
            : config.numbers[
                ((Math.random() * (config.maxNumber - config.minNumber + 1)) |
                  0) +
                  config.minNumber
              ];
        // const num = config.minNumber;
        board.cell[
          (board.rows - 1 - y) * board.cols + (board.cols - 1 - x)
        ].qdir = dir;
        board.cell[
          (board.rows - 1 - y) * board.cols + (board.cols - 1 - x)
        ].qnum = num;
      }
    }

    counter = 0;
    prevBoard = createBoard(11, 11);
    prevScore = 0;
  } else {
    board.cell = prevBoard.cell.map((e) => ({
      qdir: e.qdir,
      qnum: e.qnum,
    }));
    const question = board.cell.filter((e) => e.qnum === -2);
    if (question.length > 0 && Math.random() < 0.5) {
      question.forEach((e) => {
        e.qdir = ((Math.random() * 4) | 0) + 1;
        e.qnum =
          config.numbers[
            ((Math.random() * (config.maxNumber - config.minNumber + 1)) | 0) +
              config.minNumber
          ];
      });
    } else {
      if (Math.random() > config.moveRate) {
        for (let i = 0; i < 2; ++i) {
          const r = (Math.random() * config.clueCount) | 0;
          const cell = board.cell.filter((e) => e.qnum !== -1)[r];
          if (!cell) {
            continue;
          }
          cell.qnum =
            config.numbers[
              ((Math.random() * (config.maxNumber - config.minNumber + 1)) |
                0) +
                config.minNumber
            ];
          cell.qdir = ((Math.random() * 4) | 0) + 1;
        }
      } else {
        const r = (Math.random() * config.clueCount) | 0;
        const cell1 = board.cell
          .map((e, i): [Cell, number] => [e, i])
          .filter((e) => e[0].qnum !== -1)[r];

        if (!cell1 || !cell1[0]) {
          return board;
        }
        const rDir = ((Math.random() * 4) | 0) + 1;
        for (let d = 0; ++d <= 4; ) {
          const dir = ((rDir + d) % 4) + 1;
          if (dir === 1 && cell1[1] % board.cols === 0) {
            continue;
          }
          if (dir === 2 && cell1[1] % board.cols === board.cols - 1) {
            continue;
          }
          if (dir === 3 && cell1[1] < board.cols) {
            continue;
          }
          if (dir === 4 && cell1[1] >= board.rows * board.cols - board.cols) {
            continue;
          }
          const nextIndex =
            cell1[1] +
            (dir === 1
              ? -1
              : dir === 2
              ? 1
              : dir === 3
              ? -board.cols
              : board.cols);
          if (!board.cell[nextIndex] || board.cell[nextIndex].qnum !== -1) {
            continue;
          }

          if (config.symmetry) {
            const cell2 = board.cell[board.rows * board.cols - 1 - cell1[1]];
            if (!cell2 || cell2.qnum !== -1) {
              continue;
            }
          }
          board.cell[nextIndex].qdir = cell1[0].qdir;
          board.cell[nextIndex].qnum = cell1[0].qnum;
          board.cell[cell1[1]].qdir = -1;
          board.cell[cell1[1]].qnum = -1;

          if (config.symmetry) {
            const cell2 = board.cell[board.rows * board.cols - 1 - cell1[1]];
            board.cell[board.rows * board.cols - 1 - nextIndex].qdir =
              cell2.qdir;
            board.cell[board.rows * board.cols - 1 - nextIndex].qnum =
              cell2.qnum;
            cell2.qdir = -1;
            cell2.qnum = -1;
          }

          break;
        }
      }
    }
    ++counter;
  }
  return board;
};
