export const pathfinder = (
  src: [number, number],
  dest: [number, number],
  forbidden: (x: number, y: number) => boolean
) => {
  console.log(src, dest);
  const queue = [src.concat(-1, -1)];
  const prevs: { [key: string]: [number, number] } = {};
  if (src[0] === dest[0] && src[1] === dest[1]) {
    return [];
  }
  do {
    const point = queue.shift();
    if (!point) {
      break;
    }
    if (prevs[point[0] + "," + point[1]]) {
      continue;
    }
    if (point[0] === dest[0] && point[1] === dest[1]) {
      prevs[point[0] + "," + point[1]] = [point[2], point[3]];
      break;
    }
    if (
      (point[0] !== src[0] || point[1] !== src[1]) &&
      forbidden(point[0], point[1])
    ) {
      continue;
    }
    prevs[point[0] + "," + point[1]] = [point[2], point[3]];
    queue.push([point[0] + 1, point[1], point[0], point[1]]);
    queue.push([point[0], point[1] + 1, point[0], point[1]]);
    queue.push([point[0] - 1, point[1], point[0], point[1]]);
    queue.push([point[0], point[1] - 1, point[0], point[1]]);
  } while (true);

  if (!prevs[dest[0] + "," + dest[1]]) {
    return null;
  }
  console.log(prevs);
  const ret: number[][] = [];
  let last = dest;
  do {
    ret.push(last);
    last = prevs[last[0] + "," + last[1]];
  } while (last && (last[0] !== src[0] || last[1] !== src[1]));
  ret.reverse();
  console.log(src, dest, ret.map((e) => e.join(",")).join("->"));
  return ret;
};
