import { Arrow, Text } from "react-konva";
type Props = {
  x: number;
  y: number;
  num: number;
  boardWidth: number;
  boardHeight: number;
  cellSize: number;
  center: number;
  textColor: string;
};

export const NumberWithRightArrow = (props: Props) => {
  const { x, y, num, boardWidth, boardHeight, cellSize, center,textColor } = props;
  return (
    <>
      <Text
        text={"" + (num === -2 ? "?" : num)}
        x={center - boardWidth / 2 + x * cellSize}
        y={center - boardHeight / 2 + y * cellSize}
        fontFamily={"Helvetica"}
        fontSize={cellSize * 0.8}
        align={"center"}
        verticalAlign={"bottom"}
        width={cellSize}
        height={cellSize}
        fill={textColor}
      />
      <Arrow
        x={center - boardWidth / 2 + x * cellSize}
        y={center - boardHeight / 2 + y * cellSize}
        points={[
          cellSize * 0.2,
          cellSize * 0.2,
          cellSize * 0.8,
          cellSize * 0.2,
        ]}
        pointerLength={cellSize * 0.2 * 0.6}
        pointerWidth={cellSize * 0.2 * 0.6}
        fill={textColor}
        stroke={textColor}
        strokeWidth={cellSize * 0.05}
      />
    </>
  );
};
