import { Board, toId } from "../../board";
import { Position, equals } from "../../Position";
import { ConnectionMap } from "./algorithm/connectionMap";
import { AnswerMethods } from "./createAnswerMethods";

export const closedLineNumberTheory = (
  board: Board,
  answer: AnswerMethods,
  connectionMap: ConnectionMap
) => {
  const { map, cells, areaCount } = connectionMap;
  const innerEdge: Position[][] = [...new Array(areaCount)].map(() => []);
  const boundaryEdge: Position[][] = [...new Array(areaCount)].map(() => []);
  const aroundEdge: Position[][] = [...new Array(areaCount)].map(() => []);

  let edges = answer.getAllEdge();
  let edgeCount = edges.length;
  do {
    edges = edges.filter((pos) => {
      const aroundIds = answer
        .edgeAround(pos)
        .map(({ pos: pos2 }) => map.get(toId(pos2)));
      if (aroundIds.some((e) => e === undefined)) {
        return true;
      }
      const uniqueIds = [
        ...new Set(aroundIds.filter((e) => e !== undefined) as number[]),
      ];
      if (uniqueIds.length === 1) {
        innerEdge[uniqueIds[0]].push(pos);
        map.set(toId(pos), uniqueIds[0]);
      } else {
        uniqueIds.forEach((id) => {
          boundaryEdge[id].push(pos);
        });
      }
      return false;
    });
    if (edges.length === 0) {
      break;
    }
    if (edges.length === edgeCount) {
      edges.forEach((pos) => {
        const aroundIds = answer
          .edgeAround(pos)
          .map(({ pos: pos2 }) => map.get(toId(pos2)));
        const uniqueIds = [
          ...new Set(aroundIds.filter((e) => e !== undefined) as number[]),
        ];
        if (uniqueIds.length === 1) {
          aroundEdge[uniqueIds[0]].push(pos);
        } else {
          uniqueIds.forEach((id) => {
            boundaryEdge[id].push(pos);
          });
        }
      });
      break;
    }
    edgeCount = edges.length;
  } while (true);

  aroundEdge.forEach((edge, id) => {
    edge.forEach((pos) => {
      if (
        answer
          .edgeAround(pos)
          .filter(({ pos: pos2 }) => !map.has(toId(pos2)))
          .every(
            ({ pos: pos2 }) =>
              edge.some((pos3) => equals(pos2, pos3)) ||
              boundaryEdge[id].some((pos3) => equals(pos2, pos3))
          )
      ) {
        innerEdge[id].push(pos);
        map.set(toId(pos), id);
      } else {
        boundaryEdge[id].push(pos);
      }
    });
  });

  let isAdvanced = false;
  for (let i = 0; i < areaCount; ++i) {
    if (cells[i].length === 1 && !answer.hasWhiteCell(cells[i][0])) {
      // 黒マスで埋められる可能性があるためスキップ
      continue;
    }
    // console.log(cells[i]);
    // console.log(innerEdge[i]);
    // console.log(boundaryEdge[i]);

    if (boundaryEdge[i].length === 0) {
      if (areaCount >= 2 && innerEdge[i].length === 0) {
        return "broken";
      }
      if (innerEdge[i].length % 2 === 1) {
        // console.log("小領域の本数ハタン");
        return "broken";
      } else if (
        areaCount >= 2 &&
        new Set(innerEdge[i].map((pos) => answer.getEdgeId(pos) as number))
          .size ===
          innerEdge[i].length / 2
      ) {
        return "broken";
      }
    }
    if (boundaryEdge[i].length === 1) {
      if (innerEdge[i].length % 2 === 0) {
        // 外側に向かう
        boundaryEdge[i].forEach((pos) => {
          if (isAdvanced) {
            return;
          }
          const next = answer.edgeAround(pos).filter(({ pos: pos2 }) => {
            return map.get(toId(pos2)) !== i;
          });
          if (next.length === 1) {
            isAdvanced ||= answer.addLine(
              [pos, next[0].pos],
              "本数定理による見えない壁"
            );
          }
        });
      } else {
        // 内側に向かう
        boundaryEdge[i].forEach((pos) => {
          if (isAdvanced) {
            return;
          }
          const next = answer.edgeAround(pos).filter(({ pos: pos2 }) => {
            return map.get(toId(pos2)) === i;
          });
          if (next.length === 1) {
            isAdvanced ||= answer.addLine(
              [pos, next[0].pos],
              "本数定理による見えない壁"
            );
          }
        });
      }
    }
    if (isAdvanced) {
      return "advanced";
    }
    if (boundaryEdge[i].length === 2) {
      if (innerEdge[i].length % 2 === 0) {
        if (
          new Set(innerEdge[i].map((pos) => answer.getEdgeId(pos) as number))
            .size ===
          innerEdge[i].length / 2
        ) {
          // 外側に向かう
          boundaryEdge[i].forEach((pos) => {
            if (isAdvanced) {
              return;
            }
            const next = answer.edgeAround(pos).filter(({ pos: pos2 }) => {
              return map.get(toId(pos2)) === i;
            });
            if (next.length === 1) {
              isAdvanced ||= answer.addLine(
                [pos, next[0].pos],
                "大域小ループ禁による見えない壁"
              );
              return "advanced";
            }
          });
        }
      }
    }
    if (isAdvanced) {
      return "advanced";
    }
  }
  return "stopped";
};
