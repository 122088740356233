import { Board, toId } from "../../board";
import { dirs } from "../../Position";
import { AnswerMethods } from "./createAnswerMethods";

export const addBlackCellWithNumber = (board: Board, answer: AnswerMethods) => {
  let isAdvanced = false;
  const {
    addBlackCell,
    hasBlackCell,
    addWhiteCell,
    hasLine,
    hasWhiteCell,
    isLineEdge,
    forbidden,
  } = answer;

  for (let i = 0, len = board.cell.length; i < len; i++) {
    const dir = board.cell[i].qdir;
    if (dir === -1 || dir === 0) {
      continue;
    }
    const num = board.cell[i].qnum;
    if (num === -2) {
      continue;
    }
    const dirDelta = dirs[dir];
    let x = i % board.cols;
    let y = Math.floor(i / board.cols);
    x += dirDelta.x;
    y += dirDelta.y;

    let blackCount = 0;
    const unsettledCells: { x: number; y: number }[][] = [];
    while (
      board.inBoard({ x, y }) &&
      board.cell[y * board.cols + x].qdir !== dir
    ) {
      if (hasBlackCell({ x, y })) {
        ++blackCount;
      }
      if (
        !hasLine({ x, y }) &&
        !hasBlackCell({ x, y }) &&
        !hasWhiteCell({ x, y }) &&
        !isLineEdge({ x, y }) &&
        board.cell[y * board.cols + x].qnum === -1
      ) {
        if (unsettledCells.length === 0) {
          unsettledCells.push([{ x, y }]);
        } else {
          if (
            unsettledCells[unsettledCells.length - 1][
              unsettledCells[unsettledCells.length - 1].length - 1
            ].x +
              dirDelta.x ===
              x &&
            unsettledCells[unsettledCells.length - 1][
              unsettledCells[unsettledCells.length - 1].length - 1
            ].y +
              dirDelta.y ===
              y
          ) {
            unsettledCells[unsettledCells.length - 1].push({ x, y });
          } else {
            unsettledCells.push([{ x, y }]);
          }
        }
      }
      x += dirDelta.x;
      y += dirDelta.y;
    }
    const nextNum = board.inBoard({ x, y })
      ? board.cell[y * board.cols + x].qnum
      : 0;
    const arrowNum = board.cell[i].qnum - nextNum;
    const candCount = unsettledCells.reduce(
      (a, b) => a + Math.floor((b.length + 1) / 2),
      0
    );
    if (arrowNum === candCount + blackCount) {
      unsettledCells
        .filter((cand) => cand.length % 2 === 1)
        .forEach((cand) => {
          cand.forEach((c, i) => {
            if (i % 2 === 0) {
              const flag = addBlackCell(answer, c, "1つ飛ばしの黒");
              isAdvanced ||= flag;
            }
          });
        });
      unsettledCells
        .filter((cand) => cand.length % 2 === 0)
        .forEach((cand) => {
          cand.forEach((c, i) => {
            if (i % 2 === 0) {
              if (dirDelta.x === 0) {
                answer.blackCandidatesVertical.add(
                  toId({
                    x: c.x,
                    y: c.y + Math.min(dirDelta.y, 0),
                  })
                );
                answer.setLineBar(c, {
                  x: c.x + dirDelta.x,
                  y: c.y + dirDelta.y,
                });
              } else {
                answer.blackCandidatesHorizontal.add(
                  toId({
                    x: c.x + Math.min(dirDelta.x, 0),
                    y: c.y,
                  })
                );
                answer.setLineBar(c, {
                  x: c.x + dirDelta.x,
                  y: c.y + dirDelta.y,
                });
              }
            }
          });
          const prev = {
            x: cand[0].x - dirDelta.x,
            y: cand[0].y - dirDelta.y,
          };
          const prevNeighbor1 = {
            x: cand[0].x - dirDelta.y,
            y: cand[0].y - dirDelta.x,
          };
          const prevNeighbor2 = {
            x: cand[0].x + dirDelta.y,
            y: cand[0].y + dirDelta.x,
          };
          if (forbidden(prev)) {
            if (!forbidden(prevNeighbor1)) {
              const flag1 = addWhiteCell(
                answer,
                prevNeighbor1,
                "2n in nの白(手前)"
              );
              isAdvanced ||= flag1;
            }
            if (!forbidden(prevNeighbor2)) {
              const flag2 = addWhiteCell(
                answer,
                prevNeighbor2,
                "2n in nの白(手前)"
              );
              isAdvanced ||= flag2;
            }
          }
          const next = {
            x: cand[cand.length - 1].x + dirDelta.x,
            y: cand[cand.length - 1].y + dirDelta.y,
          };
          const nextNeighbor1 = {
            x: cand[cand.length - 1].x - dirDelta.y,
            y: cand[cand.length - 1].y - dirDelta.x,
          };
          const nextNeighbor2 = {
            x: cand[cand.length - 1].x + dirDelta.y,
            y: cand[cand.length - 1].y + dirDelta.x,
          };
          if (forbidden(next)) {
            if (!forbidden(nextNeighbor1)) {
              const flag1 = addWhiteCell(
                answer,
                nextNeighbor1,
                "2n in nの白(手前)"
              );
              isAdvanced ||= flag1;
            }

            if (!forbidden(nextNeighbor2)) {
              const flag2 = addWhiteCell(
                answer,
                nextNeighbor2,
                "2n in nの白(手前)"
              );
              isAdvanced ||= flag2;
            }
          }

          const prevDiagonal1 = {
            x: cand[0].x - dirDelta.x - dirDelta.y,
            y: cand[0].y - dirDelta.y - dirDelta.x,
          };
          if (
            forbidden(prevDiagonal1) &&
            !forbidden(prevNeighbor1) &&
            !isLineEdge(prevNeighbor1)
          ) {
            const neighbor = {
              x: cand[1].x - dirDelta.y,
              y: cand[1].y - dirDelta.x,
            };
            if (!forbidden(neighbor)) {
              const flag = addWhiteCell(answer, neighbor, "2n in nの白(奥)");
              isAdvanced ||= flag;
            }
          }
          const prevDiagonal2 = {
            x: cand[0].x - dirDelta.x + dirDelta.y,
            y: cand[0].y - dirDelta.y + dirDelta.x,
          };
          if (
            forbidden(prevDiagonal2) &&
            !forbidden(prevNeighbor2) &&
            !isLineEdge(prevNeighbor2)
          ) {
            const neighbor = {
              x: cand[1].x + dirDelta.y,
              y: cand[1].y + dirDelta.x,
            };
            if (!forbidden(neighbor)) {
              const flag = addWhiteCell(answer, neighbor, "2n in nの白(奥)");
              isAdvanced ||= flag;
            }
          }
          const nextDiagonal1 = {
            x: cand[cand.length - 1].x + dirDelta.x - dirDelta.y,
            y: cand[cand.length - 1].y + dirDelta.y - dirDelta.x,
          };
          if (
            forbidden(nextDiagonal1) &&
            !forbidden(nextNeighbor1) &&
            !isLineEdge(nextNeighbor1)
          ) {
            const neighbor = {
              x: cand[cand.length - 2].x - dirDelta.y,
              y: cand[cand.length - 2].y - dirDelta.x,
            };
            if (!forbidden(neighbor)) {
              const flag = addWhiteCell(answer, neighbor, "2n in nの白(奥)");
              isAdvanced ||= flag;
            }
          }
          const nextDiagonal2 = {
            x: cand[cand.length - 1].x + dirDelta.x + dirDelta.y,
            y: cand[cand.length - 1].y + dirDelta.y + dirDelta.x,
          };
          if (
            forbidden(nextDiagonal2) &&
            !forbidden(nextNeighbor2) &&
            !isLineEdge(nextNeighbor2)
          ) {
            const neighbor = {
              x: cand[cand.length - 2].x + dirDelta.y,
              y: cand[cand.length - 2].y + dirDelta.x,
            };
            if (!forbidden(neighbor)) {
              const flag = addWhiteCell(answer, neighbor, "2n in nの白(奥)");
              isAdvanced ||= flag;
            }
          }
        });
    } else if (arrowNum > candCount + blackCount) {
      return "broken";
    } else if (arrowNum < blackCount) {
      return "broken";
    }
  }
  return isAdvanced ? "advanced" : "stopped";
};
