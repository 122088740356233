import { useCallback, useEffect, useMemo, useState } from "react";
import { Circle, Group, Layer, Line, Rect, Stage } from "react-konva";
import { Polyomino } from "../lib/polyomino/polyomino";
import { Position } from "../puzzle/Position";

const board = {
  rows: 8,
  cols: 8,
};

const solverOption = {
  allowReverse: true,
  allowRotate: true,
};

const pentominoPieces = [
  new Polyomino.Piece(4, 2, [
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
  ]),
  new Polyomino.Piece(4, 2, [
    true,
    true,
    true,
    true,
    false,
    true,
    false,
    false,
  ]),
  new Polyomino.Piece(3, 2, [true, true, true, true, true, false]),
  new Polyomino.Piece(3, 2, [true, true, true, true, false, true]),
  new Polyomino.Piece(3, 3, [
    true,
    true,
    false,
    false,
    true,
    true,
    false,
    false,
    true,
  ]),
  new Polyomino.Piece(3, 3, [
    true,
    false,
    false,
    true,
    true,
    true,
    false,
    false,
    true,
  ]),
  //----
  new Polyomino.Piece(5, 1, [true, true, true, true, true]),
  new Polyomino.Piece(4, 2, [
    true,
    true,
    true,
    false,
    false,
    false,
    true,
    true,
  ]),
  new Polyomino.Piece(3, 3, [
    true,
    true,
    true,
    true,
    false,
    false,
    true,
    false,
    false,
  ]),
  new Polyomino.Piece(3, 3, [
    true,
    true,
    true,
    false,
    true,
    false,
    false,
    true,
    false,
  ]),
  new Polyomino.Piece(3, 3, [
    true,
    false,
    false,
    true,
    true,
    true,
    false,
    true,
    false,
  ]),
  new Polyomino.Piece(3, 3, [
    false,
    true,
    false,
    true,
    true,
    true,
    false,
    true,
    false,
  ]),
];

const pentominoInfo = [
  {
    name: "L",
    rotate: 4,
    reverse: 2,
    offsetX: 1,
    offsetY: 0,
  },
  {
    name: "Y",
    rotate: 4,
    reverse: 2,
    offsetX: 1,
    offsetY: 0,
  },
  {
    name: "P",
    rotate: 4,
    reverse: 2,
    offsetX: 1,
    offsetY: 0,
  },
  {
    name: "U",
    rotate: 4,
    reverse: 1,
    offsetX: 1,
    offsetY: 0,
  },
  {
    name: "W",
    rotate: 4,
    reverse: 1,
    offsetX: 1,
    offsetY: 1,
  },
  {
    name: "S",
    rotate: 2,
    reverse: 2,
    offsetX: 1,
    offsetY: 1,
  },
  {
    name: "I",
    rotate: 2,
    reverse: 1,
    offsetX: 2,
    offsetY: 0,
  },
  {
    name: "N",
    rotate: 4,
    reverse: 2,
    offsetX: 2,
    offsetY: 0,
  },
  {
    name: "L",
    rotate: 4,
    reverse: 1,
    offsetX: 0,
    offsetY: 0,
  },
  {
    name: "T",
    rotate: 4,
    reverse: 1,
    offsetX: 1,
    offsetY: 1,
  },
  {
    name: "F",
    rotate: 4,
    reverse: 2,
    offsetX: 1,
    offsetY: 1,
  },
  {
    name: "X",
    rotate: 1,
    reverse: 1,
    offsetX: 1,
    offsetY: 1,
  },
];

const dirX = [1, 0, -1, 0];
const dirY = [0, 1, 0, -1];

const getPiecePos = (pieceId: number, rotateId: number, pos: Position) => {
  const piece = pentominoPieces[pieceId];
  const pieceInfo = pentominoInfo[pieceId];
  const isReverse = rotateId >= pentominoInfo[pieceId].rotate;
  const reversedPiece = isReverse ? piece.getReversePieces()[0] : piece;

  const isRotated = rotateId % pentominoInfo[pieceId].rotate !== 0;
  const rotatedPiece = isRotated
    ? reversedPiece.getRotatePieces()[
        (rotateId % pentominoInfo[pieceId].rotate) - 1
      ]
    : reversedPiece;

  const reversedOffsetX = isReverse
    ? piece.width - pieceInfo.offsetX - 1
    : pieceInfo.offsetX;
  const reversedOffsetY = pieceInfo.offsetY;

  const rotatedOffsetX = [
    reversedOffsetX,
    rotatedPiece.width - reversedOffsetY - 1,
    rotatedPiece.width - reversedOffsetX - 1,
    reversedOffsetY,
  ][rotateId % pieceInfo.rotate];
  const rotatedOffsetY = [
    reversedOffsetY,
    reversedOffsetX,
    rotatedPiece.height - reversedOffsetY - 1,
    rotatedPiece.height - reversedOffsetX - 1,
  ][rotateId % pieceInfo.rotate];

  const ret: Position[] = rotatedPiece.maps
    .map((e, i) => {
      if (e < 0) {
        return null;
      }
      return {
        x: (i % rotatedPiece.width) + pos.x - rotatedOffsetX,
        y: ((i / rotatedPiece.width) | 0) + pos.y - rotatedOffsetY,
      };
    })
    .filter((e) => e !== null) as Position[];
  return ret;
};

export const AQAlice = () => {
  const fullHeight = window.innerHeight;
  const fullWidth = window.innerWidth;
  const boardWidth = 1600;
  const boardHeight = 1600;
  const cellSize = boardWidth / board.cols;
  const center = 1000;
  const scale = fullWidth / 2000;
  const [ngList, setNgList] = useState<Position[]>([]);

  const [solutionList, setSolutionList] = useState<number[][]>([]);
  const [selectedPiece, setSelectedPiece] = useState<number>(-1);
  const [currentPiece, setCurrentPiece] = useState<Position[]>([]);
  const [fixedPiece, setFixedPiece] = useState<{
    [key: string]: {
      pieceId: number;
      rotateId: number;
      pos: Position;
    };
  }>({});
  const fixedBoard = useMemo(() => {
    const ret = [...new Array(board.cols * board.rows)].map(() => -1);
    ngList.forEach((e) => {
      ret[e.x + e.y * board.cols] = -2;
    });
    Object.values(fixedPiece).forEach((e, i) => {
      const pieces = getPiecePos(e.pieceId, e.rotateId, e.pos);
      pieces.forEach((pos) => {
        ret[pos.x + pos.y * board.cols] = e.pieceId;
      });
    });
    return ret;
  }, [fixedPiece, ngList]);

  const solverBoard = useMemo(() => {
    return new Polyomino.Piece(
      board.cols,
      board.rows,
      [...Array(board.cols * board.rows)].map((_, i) => fixedBoard[i] === -1)
    );
  }, [fixedBoard]);

  const solvable = useMemo(() => {
    return ngList.length == 4;
  }, [ngList]);

  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  useEffect(() => {
    if (!solvable) {
      setSolutionList([]);
      setIsCalculating(false);
      return;
    }
    setIsCalculating(true);
    setTimeout(() => {
      (async () => {
        const filteredPentominoPieces = pentominoPieces.filter((_, i) => {
          return fixedPiece[pentominoInfo[i].name] === undefined;
        });
        const mappedPentominoPieces = pentominoPieces
          .map((_, i) => {
            return fixedPiece[pentominoInfo[i].name] === undefined ? i : -1;
          })
          .filter((e) => e !== -1);

        const solver = new Polyomino.Solver(
          solverBoard,
          filteredPentominoPieces,
          solverOption
        );
        const solutions: number[][] = [];
        await solver.solveAsync(solutions);
        const result = solutions.concat().map((solution) => {
          return solution.map((e) => mappedPentominoPieces[e]);
        });
        setSolutionList(result);
        setIsCalculating(false);
      })();
    }, 1);
  }, [solverBoard, solvable]);

  const pointToPosition = (point: any) => {
    const x = point.x / scale - center;
    const y = point.y / scale - center;
    const col = Math.floor((x + boardWidth / 2) / cellSize);
    const row = Math.floor((y + boardHeight / 2) / cellSize);
    return { x: col, y: row };
  };

  const handleMouseDown = useCallback(
    (e: any) => {
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      const pos = pointToPosition(point);
      setSelectedPiece(-1);
      if (
        pos.x < 0 ||
        pos.x >= board.cols ||
        pos.y < 0 ||
        pos.y >= board.rows
      ) {
        return;
      }
      if (ngList.some((e) => e.x === pos.x && e.y === pos.y)) {
        setNgList((prev) => {
          return prev.filter((e) => e.x !== pos.x || e.y !== pos.y);
        });
        return;
      }
      setNgList((prev) => {
        if (prev.length >= 4) {
          prev.shift();
        }
        if (prev) {
          return [...prev, pos];
        } else {
          return [pos];
        }
      });
    },
    [ngList]
  );

  useEffect(() => {
    ngList.forEach((pos) => {
      if (fixedBoard[pos.x + pos.y * board.cols] >= 0) {
        setFixedPiece((prev) => {
          const {
            [pentominoInfo[fixedBoard[pos.x + pos.y * board.cols]].name]: _,
            ...rest
          } = prev;
          return rest;
        });
      }
    });
  }, [fixedBoard, ngList]);

  const handleSelectPiece = useCallback((n: number) => {
    setSelectedPiece(n);
    return false;
  }, []);

  const handleOuterMouseDown = useCallback((e: any) => {
    setSelectedPiece(-1);
  }, []);

  const width = 2000;
  const height = 2000 * (fullHeight / fullWidth);

  return (
    <Stage
      scale={{ x: scale, y: scale }}
      width={fullWidth}
      height={fullHeight}
      x={0}
    >
      <Layer>
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={"#eee"}
          onMouseDown={handleOuterMouseDown}
          onTouchStart={handleOuterMouseDown}
        />
        {(solvable || isCalculating) && (
          <Line
            stroke={
              solutionList.length > 0 ? "#0f0" : isCalculating ? "#ff0" : "#f00"
            }
            strokeWidth={cellSize * 0.2}
            points={[0, 0, width, 0]}
          />
        )}

        {[...Array(board.cols + 1)].map((_, i) => (
          <Line
            x={center}
            y={center}
            key={i}
            stroke={"#888"}
            strokeWidth={cellSize * 0.05}
            dash={[cellSize * 0.1, cellSize * 0.1]}
            points={[
              -boardWidth / 2 + cellSize * i,
              -boardHeight / 2,
              -boardWidth / 2 + cellSize * i,
              +boardHeight / 2,
            ]}
          />
        ))}
        {[...Array(board.rows + 1)].map((_, i) => (
          <Line
            x={center}
            y={center}
            key={i}
            stroke={"#888"}
            strokeWidth={cellSize * 0.05}
            dash={[cellSize * 0.1, cellSize * 0.1]}
            points={[
              -boardWidth / 2,
              -boardHeight / 2 + cellSize * i,
              +boardWidth / 2,
              -boardHeight / 2 + cellSize * i,
            ]}
          />
        ))}
        {ngList?.map((e, i) => (
          // x mark
          <>
            <Line
              x={center}
              y={center}
              key={"\\" + i}
              stroke={"#888"}
              strokeWidth={cellSize * 0.05}
              dash={[cellSize * 0.1, cellSize * 0.1]}
              points={[
                -boardWidth / 2 + cellSize * e.x,
                -boardHeight / 2 + cellSize * e.y,
                -boardWidth / 2 + cellSize * (e.x + 1),
                -boardHeight / 2 + cellSize * (e.y + 1),
              ]}
            />
            <Line
              x={center}
              y={center}
              key={"/" + i}
              stroke={"#888"}
              strokeWidth={cellSize * 0.05}
              dash={[cellSize * 0.1, cellSize * 0.1]}
              points={[
                -boardWidth / 2 + cellSize * (e.x + 1),
                -boardHeight / 2 + cellSize * e.y,
                -boardWidth / 2 + cellSize * e.x,
                -boardHeight / 2 + cellSize * (e.y + 1),
              ]}
            />
          </>
        ))}
        {selectedPiece >= 0 &&
          [
            ...new Array(
              pentominoInfo[selectedPiece].rotate *
                pentominoInfo[selectedPiece].reverse
            ),
          ].map((_, i) => (
            <Group
              x={
                cellSize * 1.1 * i +
                width / 2 -
                boardWidth / 2 +
                cellSize * 0.15
              }
              y={boardHeight + 1.7 * cellSize}
              width={cellSize * 1}
              height={cellSize * 1}
              offsetX={cellSize * 0.5}
              offsetY={cellSize * 0.5}
              rotation={i * 90}
              scaleX={i >= pentominoInfo[selectedPiece].rotate ? -1 : 1}
              key={"marker-" + i}
              draggable
              onMouseDown={() => {
                return true;
              }}
              onTouchStart={() => {
                return false;
              }}
              onDragMove={(e) => {
                const stage = e.target.getStage();
                if (!stage) {
                  return;
                }
                const point = stage.getPointerPosition();
                const pos = pointToPosition(point);
                const piece = getPiecePos(selectedPiece, i, pos);

                if (
                  piece.every(
                    (e) =>
                      e.x >= 0 &&
                      e.x < board.cols &&
                      e.y >= 0 &&
                      e.y < board.rows
                  )
                ) {
                  setCurrentPiece(piece);
                } else {
                  setCurrentPiece([]);
                }
              }}
              onDragEnd={(e) => {
                const stage = e.target.getStage();
                if (!stage) {
                  return;
                }
                const point = stage.getPointerPosition();
                const pos = pointToPosition(point);
                const piece = getPiecePos(selectedPiece, i, pos);

                if (
                  piece.every(
                    (e) =>
                      e.x >= 0 &&
                      e.x < board.cols &&
                      e.y >= 0 &&
                      e.y < board.rows &&
                      (fixedBoard[e.y * board.cols + e.x] === -1 ||
                        fixedBoard[e.y * board.cols + e.x] === selectedPiece)
                  )
                ) {
                  setFixedPiece((prev) => {
                    return {
                      ...prev,
                      [pentominoInfo[selectedPiece].name]: {
                        pieceId: selectedPiece,
                        rotateId: i,
                        pos,
                      },
                    };
                  });
                } else {
                  setFixedPiece((prev) => {
                    const { [pentominoInfo[selectedPiece].name]: _, ...rest } =
                      prev;
                    return rest;
                  });
                }
                setCurrentPiece([]);
                e.target.x(
                  cellSize * 1.1 * i +
                    width / 2 -
                    boardWidth / 2 +
                    cellSize * 0.15
                );
                e.target.y(boardHeight + 1.7 * cellSize);
              }}
            >
              <Circle
                key={"circle-" + i}
                x={cellSize * 0.5}
                y={cellSize * 0.5}
                radius={cellSize * 0.5}
                fill={`hsl(${
                  (15 / 24 + ((selectedPiece / 6) | 0) / 2) * 360
                }, 100%, 50%)`}
                opacity={0.5}
              />
              {pentominoPieces[selectedPiece].maps.map((e, j) =>
                e >= 0 ? (
                  <Rect
                    key={i * 100 + j}
                    fill={`#fff`}
                    stroke={"#111"}
                    strokeWidth={cellSize * 0.02}
                    x={
                      (cellSize / 5) *
                        (j % pentominoPieces[selectedPiece].width) +
                      cellSize *
                        (0.7 -
                          (pentominoPieces[selectedPiece].width / 2 / 5) *
                            1.1) -
                      cellSize * 0.15
                    }
                    y={
                      (cellSize / 5) *
                        1 *
                        ((j / pentominoPieces[selectedPiece].width) | 0) +
                      cellSize *
                        (0.7 -
                          (pentominoPieces[selectedPiece].height / 2 / 5) *
                            1.1) -
                      cellSize * 0.15
                    }
                    width={cellSize / 5}
                    height={cellSize / 5}
                    opacity={0.9}
                  />
                ) : (
                  <></>
                )
              )}
            </Group>
          ))}

        {
          <>
            {fixedBoard.map(
              (e, i) =>
                e >= 0 && (
                  <Rect
                    key={i}
                    fill={`hsl(${
                      (15 / 24 + ((e / 6) | 0) / 2) * 360
                    }, 100%, 50%)`}
                    x={center - boardWidth / 2 + cellSize * (i % board.cols)}
                    y={
                      center -
                      boardHeight / 2 +
                      cellSize * ((i / board.cols) | 0)
                    }
                    width={cellSize}
                    height={cellSize}
                    opacity={0.5}
                  />
                )
            )}
            {fixedBoard.map(
              (e, i, arr) =>
                i % board.cols > 0 &&
                e != arr[i - 1] &&
                (e >= 0 || arr[i - 1] >= 0) && (
                  <Line
                    x={center}
                    y={center}
                    key={i}
                    stroke={"#222"}
                    strokeWidth={cellSize * 0.1}
                    points={[
                      -boardWidth / 2 + cellSize * (i % board.cols),
                      -boardHeight / 2 + cellSize * ((i / board.cols) | 0),
                      -boardWidth / 2 + cellSize * (i % board.cols),
                      -boardHeight / 2 +
                        cellSize * ((i / board.cols) | 0) +
                        cellSize,
                    ]}
                  />
                )
            )}
            {fixedBoard.map(
              (e, i, arr) =>
                i >= board.cols &&
                e != arr[i - board.cols] &&
                (e >= 0 || arr[i - board.cols] >= 0) && (
                  <Line
                    x={center}
                    y={center}
                    key={i}
                    stroke={"#222"}
                    strokeWidth={cellSize * 0.1}
                    points={[
                      -boardWidth / 2 + cellSize * (i % board.cols),
                      -boardHeight / 2 + cellSize * ((i / board.cols) | 0),
                      -boardWidth / 2 + cellSize * (i % board.cols) + cellSize,
                      -boardHeight / 2 + cellSize * ((i / board.cols) | 0),
                    ]}
                  />
                )
            )}
            {
              // top
              [...Array(board.cols)].map(
                (_, i) =>
                  fixedBoard[i] >= 0 && (
                    <Line
                      x={center - boardWidth / 2}
                      y={center - boardHeight / 2}
                      key={i}
                      stroke={"#222"}
                      strokeWidth={cellSize * 0.1}
                      points={[cellSize * i, 0, cellSize * (i + 1), 0]}
                    />
                  )
              )
            }
            {
              // bottom
              [...Array(board.cols)].map(
                (_, i) =>
                  fixedBoard[board.cols * (board.rows - 1) + i] >= 0 && (
                    <Line
                      x={center - boardWidth / 2}
                      y={center - boardHeight / 2 + boardHeight}
                      key={i}
                      stroke={"#222"}
                      strokeWidth={cellSize * 0.1}
                      points={[cellSize * i, 0, cellSize * (i + 1), 0]}
                    />
                  )
              )
            }
            {
              // left
              [...Array(board.rows)].map(
                (_, i) =>
                  fixedBoard[board.cols * i] >= 0 && (
                    <Line
                      x={center - boardWidth / 2}
                      y={center - boardHeight / 2}
                      key={i}
                      stroke={"#222"}
                      strokeWidth={cellSize * 0.1}
                      points={[0, cellSize * i, 0, cellSize * (i + 1)]}
                    />
                  )
              )
            }
            {
              // right
              [...Array(board.rows)].map(
                (_, i) =>
                  fixedBoard[board.cols * i + board.cols - 1] >= 0 && (
                    <Line
                      x={center - boardWidth / 2 + boardWidth}
                      y={center - boardHeight / 2}
                      key={i}
                      stroke={"#222"}
                      strokeWidth={cellSize * 0.1}
                      points={[0, cellSize * i, 0, cellSize * (i + 1)]}
                    />
                  )
              )
            }
          </>
        }

        {solutionList.length > 0 && selectedPiece === -1 && (
          <>
            {solutionList[0].map(
              (e, i) =>
                e >= 0 && (
                  <Rect
                    key={i}
                    fill={`hsl(${
                      (15 / 24 + ((e / 6) | 0) / 2) * 360
                    }, 100%, 50%)`}
                    x={center - boardWidth / 2 + cellSize * (i % board.cols)}
                    y={
                      center -
                      boardHeight / 2 +
                      cellSize * ((i / board.cols) | 0)
                    }
                    width={cellSize}
                    height={cellSize}
                    opacity={0.5}
                  />
                )
            )}
            {solutionList[0].map(
              (e, i, arr) =>
                i % board.cols > 0 &&
                e != arr[i - 1] && (
                  <Line
                    x={center}
                    y={center}
                    key={i}
                    stroke={"#222"}
                    strokeWidth={cellSize * 0.1}
                    points={[
                      -boardWidth / 2 + cellSize * (i % board.cols),
                      -boardHeight / 2 + cellSize * ((i / board.cols) | 0),
                      -boardWidth / 2 + cellSize * (i % board.cols),
                      -boardHeight / 2 +
                        cellSize * ((i / board.cols) | 0) +
                        cellSize,
                    ]}
                  />
                )
            )}
            {solutionList[0].map(
              (e, i, arr) =>
                i >= board.cols &&
                e != arr[i - board.cols] && (
                  <Line
                    x={center}
                    y={center}
                    key={i}
                    stroke={"#222"}
                    strokeWidth={cellSize * 0.1}
                    points={[
                      -boardWidth / 2 + cellSize * (i % board.cols),
                      -boardHeight / 2 + cellSize * ((i / board.cols) | 0),
                      -boardWidth / 2 + cellSize * (i % board.cols) + cellSize,
                      -boardHeight / 2 + cellSize * ((i / board.cols) | 0),
                    ]}
                  />
                )
            )}
            {
              // around board
              <Rect
                x={center - boardWidth / 2}
                y={center - boardHeight / 2}
                width={boardWidth}
                height={boardHeight}
                stroke={"#222"}
                strokeWidth={cellSize * 0.1}
              />
            }
          </>
        )}

        {currentPiece.map((pos, i) => (
          <Rect
            key={i}
            fill={`hsl(${
              (15 / 24 + ((selectedPiece / 6) | 0) / 2) * 360
            }, 100%, 50%)`}
            x={center - boardWidth / 2 + cellSize * pos.x}
            y={center - boardHeight / 2 + cellSize * pos.y}
            width={cellSize}
            height={cellSize}
            opacity={0.5}
          />
        ))}

        {pentominoPieces.map((piece, i) => (
          <>
            <Rect
              x={
                center -
                boardWidth / 2 +
                cellSize * 1.4 * (i % 6) -
                cellSize * 0.2
              }
              y={
                boardHeight +
                center -
                boardHeight / 2 +
                cellSize * 1.4 * ((i / 6) | 0) +
                cellSize * 1.7
              }
              width={cellSize * 1.4}
              height={cellSize * 1.4}
              fill={`hsl(${(15 / 24 + ((i / 6) | 0) / 2) * 360}, 100%, 50%)`}
              opacity={i === selectedPiece ? 0.9 : 0.5}
            />
            {piece.maps.map((e, j) =>
              e >= 0 ? (
                <Rect
                  key={i * 100 + j}
                  fill={`#fff`}
                  stroke={"#111"}
                  strokeWidth={cellSize * 0.02}
                  x={
                    center -
                    boardWidth / 2 +
                    cellSize * 1.4 * (i % 6) +
                    (cellSize / 5) * 1.2 * (j % piece.width) +
                    cellSize * (0.7 - (piece.width / 2 / 5) * 1.2) -
                    cellSize * 0.2
                  }
                  y={
                    boardHeight +
                    center -
                    boardHeight / 2 +
                    cellSize * 1.4 * ((i / 6) | 0) +
                    (cellSize / 5) * 1.2 * ((j / piece.width) | 0) +
                    cellSize * (0.7 - (piece.height / 2 / 5) * 1.2) +
                    cellSize * 1.7
                  }
                  width={(cellSize / 5) * 1.2}
                  height={(cellSize / 5) * 1.2}
                  opacity={0.9}
                />
              ) : (
                <></>
              )
            )}
            <Rect
              x={
                center -
                boardWidth / 2 +
                cellSize * 1.4 * (i % 6) -
                cellSize * 0.2
              }
              y={
                boardHeight +
                center -
                boardHeight / 2 +
                cellSize * 1.4 * ((i / 6) | 0) +
                cellSize * 1.7
              }
              width={cellSize * 1.4}
              height={cellSize * 1.4}
              opacity={0}
              key={i}
              onMouseDown={() => handleSelectPiece(i)}
              onTouchStart={() => handleSelectPiece(i)}
            />
          </>
        ))}
        <Rect
          x={width / 2 - boardWidth / 2}
          y={width / 2 - boardHeight / 2}
          width={boardWidth}
          height={boardHeight}
          opacity={0}
          onTouchStart={handleMouseDown}
          onMouseDown={handleMouseDown}
        />
      </Layer>
    </Stage>
  );
};
