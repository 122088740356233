import { Board, toId, toPos } from "../../board";
import { around, Position } from "../../Position";
import * as Model from "../answerModel";
import { addWhiteAroundSingleBlack } from "./addWhiteAroundSingleBlack";

export type AnswerMethods = {
  addBlackCell: (
    answerMethods: AnswerMethods,
    pos: Position,
    reason: string
  ) => boolean;
  addWhiteCell: (
    answerMethods: AnswerMethods,
    pos: Position,
    reason: string
  ) => boolean;
  addLine: (line: Position[], reason: string) => boolean;
  isSingleLine: () => boolean;
  isSameLine: (pos1: Position, pos2: Position) => boolean;
  isLineEdge: (pos: Position) => boolean;
  hasBlackCell: (pos: Position) => boolean;
  hasWhiteCell: (pos: Position) => boolean;
  hasLine: (pos: Position) => boolean;
  getBlackCells: () => Position[];
  getWhiteCells: () => Position[];
  getAllEdge: () => Position[];
  forbidden: (pos: Position) => boolean;
  getEdgeId: (pos: Position) => number | undefined;
  isLineConnection: (pos1: Position, pos2: Position) => boolean;
  edgeAround: (pos: Position) => { pos: Position; dir: Position }[];
  hasSmallLoop: () => boolean;
  getLineState: (pos1: Position, pos2: Position) => Model.LineState | undefined;
  setLineBar: (pos1: Position, pos2: Position) => void;
  blackCandidatesHorizontal: Set<number>;
  blackCandidatesVertical: Set<number>;
  newBlacks: Set<number>;
  newWhites: Set<number>;
};

export const createAnswerMethods = (
  board: Board,
  answer: Model.AnswerModel,
  log: boolean
): AnswerMethods => {
  const blackCandidatesHorizontal = new Set<number>();
  const blackCandidatesVertical = new Set<number>();
  const newBlacks = new Set<number>();
  const newWhites = new Set<number>();

  const addBlackCell = (
    answerMethods: AnswerMethods,
    pos: Position,
    reason: string
  ) => {
    const posId = toId(pos);
    if (Model.hasBlackCell(answer.blackCells, posId)) return false;
    Model.addBlackCell(answer.blackCells, pos);
    newBlacks.add(posId);
    if (log) {
      console.log("黒マスを", pos.x + 1, pos.y + 1, "に配置", reason);
    }
    addWhiteAroundSingleBlack(answerMethods, pos);

    blackCandidatesHorizontal.has(posId) &&
      blackCandidatesHorizontal.delete(posId);
    blackCandidatesHorizontal.has(posId - 1) &&
      blackCandidatesHorizontal.delete(posId - 1);
    blackCandidatesVertical.has(posId) && blackCandidatesVertical.delete(posId);
    blackCandidatesVertical.has(posId - 65536) &&
      blackCandidatesVertical.delete(posId - 65536);
    return true;
  };
  const addWhiteCell = (
    answerMethods: AnswerMethods,
    pos: Position,
    reason: string
  ) => {
    const posId = toId(pos);
    if (Model.hasWhiteCell(answer.whiteCells, pos)) return false;
    if (Model.isLineEdge(answer, pos)) return false;
    if (answer.innerLine.has(posId)) return false;
    Model.addWhiteCell(answer.whiteCells, pos);
    newWhites.add(posId);
    if (log) {
      console.log("白マスを", pos.x + 1, pos.y + 1, "に配置", reason);
    }

    if (blackCandidatesHorizontal.has(posId)) {
      blackCandidatesHorizontal.delete(posId);
      addBlackCell(answerMethods, { x: pos.x + 1, y: pos.y }, "2択の黒が確定");
    }
    if (blackCandidatesHorizontal.has(posId - 1)) {
      blackCandidatesHorizontal.delete(posId - 1);
      addBlackCell(answerMethods, { x: pos.x - 1, y: pos.y }, "2択の黒が確定");
    }
    if (blackCandidatesVertical.has(posId)) {
      blackCandidatesVertical.delete(posId);
      addBlackCell(answerMethods, { x: pos.x, y: pos.y + 1 }, "2択の黒が確定");
    }
    if (blackCandidatesVertical.has(posId - 65536)) {
      blackCandidatesVertical.delete(posId - 65536);
      addBlackCell(answerMethods, { x: pos.x, y: pos.y - 1 }, "2択の黒が確定");
    }
    return true;
  };
  const addLine = (line: Position[], reason: string) => {
    if (line.length === 2 && Model.isLineConnection(answer, line[0], line[1])) {
      return false;
    }
    Model.addLine(answer, line);
    if (log) {
      console.log(
        "線を",
        line.map((pos) => `(${pos.x + 1},${pos.y + 1})`).join("->"),
        "と引く",
        reason
      );
    }
    return true;
  };
  const isSingleLine = () => Model.isSingleLine(answer);
  const isSameLine = (pos1: Position, pos2: Position) =>
    Model.isSameLine(answer, pos1, pos2);
  const isLineEdge = (pos: Position) => Model.isLineEdge(answer, pos);
  const hasBlackCell = (pos: Position) =>
    Model.hasBlackCell(answer.blackCells, toId(pos));
  const hasWhiteCell = (pos: Position) =>
    Model.hasWhiteCell(answer.whiteCells, pos);
  const hasLine = (pos: Position) => Model.hasLine(answer, toId(pos));
  const getEdgeId = (pos: Position) => Model.getEdgeId(answer, pos);
  const getBlackCells = () => Model.getBlackCells(answer.blackCells);
  const getWhiteCells = () => Model.getWhiteCells(answer.whiteCells);
  const getLineState = (pos1: Position, pos2: Position) =>
    Model.getLineState(answer, pos1, pos2);
  const forbidden = (pos: Position) => {
    const posId = toId(pos);
    return (
      !board.inBoard(pos) ||
      Model.hasBlackCell(answer.blackCells, posId) ||
      board.cell[pos.y * board.cols + pos.x].qnum !== -1 ||
      Model.hasLine(answer, posId)
    );
  };
  const edgeAround = (pos: Position) => {
    return around(pos).filter(
      ({ pos: pos2 }) =>
        !forbidden(pos2) &&
        (Model.getLineState(answer, pos, pos2) ?? 0) === Model.LineState.Blank
    );
  };
  const hasSmallLoop = () => {
    return (
      answer.hasLoop && (answer.edge.size > 0 || answer.whiteCells.size > 0)
    );
  };

  const isLineConnection = (pos1: Position, pos2: Position) =>
    Model.isLineConnection(answer, pos1, pos2);
  const setLineBar = (pos1: Position, pos2: Position) =>
    Model.setLineBar(answer, pos1, pos2);
  return {
    addBlackCell,
    addWhiteCell,
    addLine,
    isSingleLine,
    isSameLine,
    isLineEdge,
    hasBlackCell,
    hasWhiteCell,
    hasLine,
    getBlackCells,
    getWhiteCells,
    getAllEdge: () => Model.getAllEdge(answer),
    forbidden,
    getEdgeId,
    isLineConnection,
    edgeAround,
    hasSmallLoop,
    getLineState,
    setLineBar,
    blackCandidatesHorizontal,
    blackCandidatesVertical,
    newBlacks,
    newWhites,
  };
};
