export type Position = {
  x: number;
  y: number;
};

export const equals = (a: Position, b: Position) => {
  return a.x === b.x && a.y === b.y;
};

export const dirs: { [key: number]: Position } = {
  1: { x: 0, y: -1 },
  2: { x: 0, y: 1 },
  3: { x: -1, y: 0 },
  4: { x: 1, y: 0 },
};
export const dirList = Object.values(dirs);

export const around = (pos: Position) =>
  dirList.map((dir) => ({ pos: { x: pos.x + dir.x, y: pos.y + dir.y }, dir }));

export const add = (a: Position, b: Position) => ({
  x: a.x + b.x,
  y: a.y + b.y,
});
export const subtract = (a: Position, b: Position) => ({
  x: a.x - b.x,
  y: a.y - b.y,
});