export class UnionFind {
  parents: { [key: number]: number };

  public constructor() {
    this.parents = [];
  }

  public static fromUF(arr: UnionFind): UnionFind {
    const uf = new UnionFind();
    uf.parents = { ...arr.parents };
    return uf;
  }

  public root(a: number): number {
    if (this.parents[a] === undefined || this.parents[a] < 0) {
      return a;
    }
    return (this.parents[a] = this.root(this.parents[a]));
  }

  public size(a: number): number {
    return -this.parents[this.root(a)];
  }

  public connect(a: number, b: number): boolean {
    let ra = this.root(a);
    let rb = this.root(b);
    if (ra === rb) {
      return false;
    }

    if (this.size(ra) < this.size(rb)) {
      const tmp = ra;
      ra = rb;
      rb = tmp;
    }
    this.parents[ra] = (this.parents[ra] ?? -1) + (this.parents[rb] ?? -1);
    this.parents[rb] = ra;
    return true;
  }

  public isUnion(a: number, b: number): boolean {
    const ra = this.root(a);
    const rb = this.root(b);
    return ra === rb;
  }
}
