import { around } from "../../Position";
import { AnswerMethods } from "./createAnswerMethods";

export const addWhiteAroundBlack = (answer: AnswerMethods) => {
  const { addWhiteCell, hasBlackCell, forbidden, getBlackCells } = answer;
  let isAdvanced = false;
  getBlackCells().forEach((black) => {
    let broken = false;
    around(black).forEach(({ pos }) => {
      if (!forbidden(pos)) {
        const flag = addWhiteCell(answer, pos, "黒の周りの白");
        isAdvanced ||= flag;
      } else if (hasBlackCell(pos)) {
        broken = true;
      }
    });
    if (broken) {
      return "broken";
    }
  });
  return isAdvanced ? "advanced" : "stopped";
};
