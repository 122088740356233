import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Yajilin } from "../../components/Yajilin";

export const Gridkit = () => {
  const [input, setInput] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" component="div">
            GridKit
          </Typography>
        </Toolbar>
      </AppBar>

      <Stack spacing={2}>
        <Item>
          {" "}
          <TextField
            id="outlined-basic"
            label="puzz.linkのURLを入力"
            variant="outlined"
            sx={{ my: 1, width: "80%" }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            disabled={open}
          />
          <Button
            variant="contained"
            sx={{ my: 1, height: 50 }}
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            {open ? "編集" : "開く"}
          </Button>
        </Item>
        <Item></Item>
      </Stack>
      {/*<Yajilin />*/}
    </>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
