import { Stage, Layer, Line, Rect } from "react-konva";
import _ReactRough, { Rectangle } from "../../lib/react-rough";
import { Board, toPos } from "../board";
import { LineState } from "../yajilin/answerModel";
import { Html as _Html } from "react-konva-utils";
import { Answers } from "../yajilin/useYajilinAnswer";
import { NumberWithBottomArrow } from "../../components/uiElements/NumberWithBottomArrow";
import { NumberWithLeftArrow } from "../../components/uiElements/NumberWithLeftArrow";
import { NumberWithRightArrow } from "../../components/uiElements/NumberWithRightArrow";
import { NumberWithTopArrow } from "../../components/uiElements/NumberWithTopArrow";
import styled from "styled-components";

type BoardView = {
  center: number;
  board: Board;
  answers: Answers;
  boardWidth: number;
  boardHeight: number;
  cellSize: number;
  cx: number;
  cy: number;
  handleMouseDown: (e: any) => void;
  handleMouseUp: (e: any) => void;
  handleMouseMove: (e: any) => void;
  onClick: (x: number, y: number) => void;
  onRightClick: (x: number, y: number) => void;
  overRef1: React.MutableRefObject<any>;
  overRef2: React.MutableRefObject<any>;
  lines: {
    tool: string;
    points: number[];
  }[];
};

const fullHeight = window.innerHeight;
const fullWidth = window.innerWidth;
export const BoardView = (props: BoardView) => {
  const {
    center,
    board,
    answers,
    boardWidth,
    boardHeight,
    cellSize,
    cx,
    cy,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    onClick,
    onRightClick,
    overRef1,
    overRef2,
    lines,
  } = props;

  const { fixedLines } = answers;

  return (
    <Stage
      scale={{ x: fullHeight / 2000, y: fullHeight / 2000 }}
      width={fullWidth}
      height={fullHeight}
      x={(fullWidth - fullHeight) / 2}
    >
      <Layer>
        {[...Array(board.cols)].map((_, x) =>
          [...Array(board.rows)]
            .map(
              (_, y) =>
                board.cell[y * board.cols + x].qnum !== -1 &&
                [
                  ,
                  <NumberWithTopArrow
                    x={x}
                    y={y}
                    num={board.cell[y * board.cols + x].qnum}
                    key={x + "," + y}
                    center={center}
                    boardWidth={boardWidth}
                    boardHeight={boardHeight}
                    cellSize={cellSize}
                    textColor={"#000"}
                  />,
                  <NumberWithBottomArrow
                    x={x}
                    y={y}
                    num={board.cell[y * board.cols + x].qnum}
                    key={x + "," + y}
                    center={center}
                    boardWidth={boardWidth}
                    boardHeight={boardHeight}
                    cellSize={cellSize}
                    textColor={"#000"}
                  />,
                  <NumberWithLeftArrow
                    x={x}
                    y={y}
                    num={board.cell[y * board.cols + x].qnum}
                    key={x + "," + y}
                    center={center}
                    boardWidth={boardWidth}
                    boardHeight={boardHeight}
                    cellSize={cellSize}
                    textColor={"#000"}
                  />,
                  <NumberWithRightArrow
                    x={x}
                    y={y}
                    num={board.cell[y * board.cols + x].qnum}
                    key={x + "," + y}
                    center={center}
                    boardWidth={boardWidth}
                    boardHeight={boardHeight}
                    cellSize={cellSize}
                    textColor={"#000"}
                  />,
                ][board.cell[y * board.cols + x].qdir]
            )
            .filter((e) => e)
        )}
        <Html>
          <ReactRough width={center * 2} height={center * 2} config={{}}>
            {[...Array(board.cols)].map((_, x) =>
              [...Array(board.rows)].map((_, y) => (
                <Rectangle
                  key={"rough" + x + "," + y}
                  fill={
                    answers.hasBlackCell({ x, y })
                      ? "rgba(64,64,64,0.8)"
                      : "rgba(64,64,64,0)"
                  }
                  height={cellSize * 0.8}
                  width={cellSize * 0.8}
                  x={center - boardWidth / 2 + cellSize * 0.07 + x * cellSize}
                  y={
                    center -
                    boardHeight / 2 +
                    cellSize * 0.07 +
                    y * cellSize +
                    ((answers.hasBlackCell({ x, y }) ? 1 : 0) * Math.random()) /
                      10000
                  }
                  fillStyle={"zigzag"}
                  hachureGap={12}
                  fillWeight={3}
                  roughness={1}
                  stroke={"rgba(64,64,64,0)"}
                  seed={(x * board.cols + y) * 10000 + 4123}
                />
              ))
            )}
          </ReactRough>

          <Stage width={center * 2} height={center * 2} x={0}>
            <Layer>
              <Line
                x={center - boardWidth / 2 + cx * cellSize}
                y={center - boardHeight / 2 + cy * cellSize}
                points={[
                  0.01 * cellSize,
                  0.01 * cellSize,
                  0.01 * cellSize,
                  0.01 * cellSize,
                  0.99 * cellSize,
                  0.01 * cellSize,
                  0.01 * cellSize,
                  0.99 * cellSize,
                ]}
                width={cellSize}
                height={cellSize}
                fill={"white"}
                stroke={"white"}
                closed
                ref={overRef1}
              />
              <Line
                x={center - boardWidth / 2 + cx * cellSize}
                y={center - boardHeight / 2 + cy * cellSize}
                points={[
                  0.99 * cellSize,
                  0.01 * cellSize,
                  0.01 * cellSize,
                  0.99 * cellSize,
                  0.99 * cellSize,
                  0.99 * cellSize,
                ]}
                width={cellSize}
                height={cellSize}
                fill={"white"}
                stroke={"white"}
                closed
                ref={overRef2}
              />
              <Rect
                x={center - boardWidth / 2}
                y={center - boardHeight / 2}
                width={boardWidth}
                height={boardHeight}
                stroke={"black"}
                strokeWidth={cellSize * 0.1}
              />
              {[...Array(board.cols)].map((_, i) => (
                <Line
                  x={center}
                  y={center}
                  key={i}
                  stroke={"black"}
                  strokeWidth={cellSize * 0.02}
                  points={[
                    -boardWidth / 2 + cellSize * i,
                    -boardHeight / 2,
                    -boardWidth / 2 + cellSize * i,
                    +boardHeight / 2,
                  ]}
                />
              ))}
              {[...Array(board.rows)].map((_, i) => (
                <Line
                  x={center}
                  y={center}
                  key={i}
                  stroke={"black"}
                  strokeWidth={cellSize * 0.02}
                  points={[
                    -boardWidth / 2,
                    -boardHeight / 2 + cellSize * i,
                    +boardWidth / 2,
                    -boardHeight / 2 + cellSize * i,
                  ]}
                />
              ))}
              {[...answers.horizontal]
                .filter((e) => e[1] === LineState.Bar)
                .map((e, i) => {
                  const { x, y } = toPos(e[0]);
                  return (
                    <>
                      <Line
                        x={center}
                        y={center}
                        key={"horizontal--" + i}
                        stroke={"green"}
                        strokeWidth={cellSize * 0.02}
                        points={[
                          -boardWidth / 2 + cellSize * (x + 1) - 10,
                          -boardHeight / 2 + cellSize * (y + 0.5) - 10,
                          -boardWidth / 2 + cellSize * (x + 1) + 10,
                          -boardHeight / 2 + cellSize * (y + 0.5) + 10,
                        ]}
                      />
                      <Line
                        x={center}
                        y={center}
                        key={"vertical--" + i}
                        stroke={"green"}
                        strokeWidth={cellSize * 0.02}
                        points={[
                          -boardWidth / 2 + cellSize * (x + 1) - 10,
                          -boardHeight / 2 + cellSize * (y + 0.5) + 10,
                          -boardWidth / 2 + cellSize * (x + 1) + 10,
                          -boardHeight / 2 + cellSize * (y + 0.5) - 10,
                        ]}
                      />
                    </>
                  );
                })}
              {[...answers.vertical]
                .filter((e) => e[1] === LineState.Bar)
                .map((e, i) => {
                  const { x, y } = toPos(e[0]);
                  return (
                    <>
                      <Line
                        x={center}
                        y={center}
                        key={"horizontal-/-" + i}
                        stroke={"green"}
                        strokeWidth={cellSize * 0.02}
                        points={[
                          -boardWidth / 2 + cellSize * (x + 0.5) - 10,
                          -boardHeight / 2 + cellSize * (y + 1) - 10,
                          -boardWidth / 2 + cellSize * (x + 0.5) + 10,
                          -boardHeight / 2 + cellSize * (y + 1) + 10,
                        ]}
                      />
                      <Line
                        x={center}
                        y={center}
                        key={"vertical-/-" + i}
                        stroke={"green"}
                        strokeWidth={cellSize * 0.02}
                        points={[
                          -boardWidth / 2 + cellSize * (x + 0.5) - 10,
                          -boardHeight / 2 + cellSize * (y + 1) + 10,
                          -boardWidth / 2 + cellSize * (x + 0.5) + 10,
                          -boardHeight / 2 + cellSize * (y + 1) - 10,
                        ]}
                      />
                    </>
                  );
                })}
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke={line.tool === "eraser" ? "darkred" : "green"}
                  strokeWidth={5}
                  opacity={0.5}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={"source-over"}
                />
              ))}
            </Layer>
          </Stage>
        </Html>
      </Layer>
      <Layer>
        <Html>
          <Stage
            width={center * 2}
            height={center * 2}
            x={0}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
          >
            <Layer>
              {[...Array(board.cols)].map((_, x) =>
                [...Array(board.rows)].map((_, y) => (
                  <Rect
                    key={x + "," + y}
                    x={center - boardWidth / 2 + x * cellSize + 0.45 * cellSize}
                    opacity={answers.hasWhiteCell({ x, y }) ? 1 : 0}
                    y={
                      center - boardHeight / 2 + y * cellSize + 0.45 * cellSize
                    }
                    width={0.1 * cellSize}
                    height={0.1 * cellSize}
                    fill={"green"}
                  />
                ))
              )}
              {[...Array(board.cols)].map((_, x) =>
                [...Array(board.rows)].map((_, y) => (
                  <Rect
                    key={x + "," + y}
                    x={center - boardWidth / 2 + x * cellSize}
                    y={center - boardHeight / 2 + y * cellSize}
                    width={cellSize}
                    height={cellSize}
                    opacity={0}
                    onContextMenu={(e) => {
                      e.evt.preventDefault();
                      onRightClick(x, y);
                    }}
                    onClick={(e) => {
                      e.evt.preventDefault();
                      e.evt.button === 0 && onClick(x, y);
                    }}
                  />
                ))
              )}
              {fixedLines.map((line, i) => (
                <Line
                  key={i}
                  points={line
                    .map((p) => [
                      (p.x + 0.5) * cellSize + center - boardWidth / 2,
                      (p.y + 0.5) * cellSize + center - boardHeight / 2,
                    ])
                    .flat()}
                  stroke="green"
                  strokeWidth={10}
                  opacity={1}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  onMouseEnter={(e) => {
                    e.target.to({
                      strokeWidth: 20,
                      duration: 0.15,
                    });
                  }}
                  onMouseOut={(e) => {
                    e.target.to({
                      strokeWidth: 10,
                      duration: 0.15,
                    });
                  }}
                />
              ))}
            </Layer>
          </Stage>
        </Html>
      </Layer>
    </Stage>
  );
};

const ReactRough = styled(_ReactRough)`
  pointer-events: none;
`;
const Html = styled(_Html)`
  pointer-events: none;
`;
