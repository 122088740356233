import { useState } from "react";
import { Board, toId, toPos } from "../board";
import { around, Position } from "../Position";
import * as Model from "./answerModel";
import { defaultMethodConfig, MethodConfig } from "./methodConfig";
import { addBlackCellWithNumber } from "./solver/addBlackCellWithNumber";
import { addFixedLine } from "./solver/addFixedLine";
import { addWhiteAroundBlack } from "./solver/addWhiteAroundBlack";
import { addWhiteCellWithZero } from "./solver/addWhiteCellWithZero";
import {
  AnswerMethods,
  createAnswerMethods,
} from "./solver/createAnswerMethods";
import { lineNumberTheory } from "./solver/lineNumberTheory";
import { rowColLineNumberTheory } from "./solver/rowColLineNumberTheory";
import { Answers } from "./useYajilinAnswer";

type Broken =
  | {
      count: number;
      target: Position;
      type: "white" | "black";
      reason: "contradiction";
    }
  | {
      count: number;
      target: Position;
      type: "white" | "black";
      reason: "any-case";
      reasonPos: Position;
    }
  | {
      count: number;
      line: Position[];
      type: "line" | "bar";
      reason: "contradiction" | "any-case";
    };
type Status = "advanced" | "stopped" | "broken" | "completed";

export const useYajilinSolver = (board: Board, answers: Answers) => {
  const [timeList, setTimeList] = useState<number[]>([]);
  const [solveCount, setSolveCount] = useState<number>(0);

  const solve = (answer: AnswerMethods): Status => {
    setSolveCount((c) => c + 1);
    // console.log("solve");
    addWhiteAroundBlack(answer);
    const status1 = addBlackCellWithNumber(board, answer);
    if (status1 === "broken") {
      return "broken";
    }
    const status2 = addWhiteCellWithZero(board, answer);
    const status4 = addFixedLine(board, answer);
    if (status4 === "broken") {
      return "broken";
    }

    if (answer.hasSmallLoop()) {
      return "broken";
    }
    if (
      status1 === "stopped" &&
      status2 === "stopped" &&
      status4 === "stopped"
    ) {
      const status5 = rowColLineNumberTheory(board, answer);
      if (status5 === "stopped") {
        const status6 = lineNumberTheory(board, answer);
        return status6;
      }
      return status5;
    }
    return "advanced";
  };

  const trySolve = (
    answer: Model.AnswerModel,
    answerMethods: AnswerMethods,
    tryLog: boolean
  ) => {
    if (tryLog) {
      console.log("try");
    }
    const brokenList: Broken[] = [];

    let minCountFlag = false;
    let minCount = 100;

    // black and white
    if (!minCountFlag) {
      for (let i = 0, len = board.cell.length; i < len; i++) {
        const targetX = i % board.cols;
        const targetY = Math.floor(i / board.cols);
        const target = { x: targetX, y: targetY };
        if (board.cell[i].qnum !== -1) {
          continue;
        }
        if (Model.hasBlackCell(answer.blackCells, toId(target))) {
          continue;
        }
        if (Model.hasWhiteCell(answer.whiteCells, target)) {
          continue;
        }
        if (Model.hasLineWithEnd(answer, target)) {
          continue;
        }

        const answerBlack = Model.clone(answer);
        const answerMethodsBlack = createAnswerMethods(
          board,
          answerBlack,
          false
        );
        Model.addBlackCell(answerBlack.blackCells, target);
        let blackStatus = "advanced";
        let blackCount = 0;
        do {
          blackStatus = solve(answerMethodsBlack);
          ++blackCount;
          if (blackCount > minCount) {
            blackStatus = "stopped";
          }
        } while (blackStatus === "advanced");
        if (blackStatus === "broken") {
          brokenList.push({
            target,
            count: blackCount,
            type: "white",
            reason: "contradiction",
          });
          if (blackCount === 1) {
            minCountFlag = true;
            break;
          }
          if (blackCount < minCount) {
            minCount = blackCount;
          }
        }

        const answerWhite = Model.clone(answer);
        const answerMethodsWhite = createAnswerMethods(
          board,
          answerWhite,
          false
        );
        Model.addWhiteCell(answerWhite.whiteCells, target);
        let whiteStatus = "advanced";
        let whiteCount = 0;
        do {
          whiteStatus = solve(answerMethodsWhite);
          ++whiteCount;
          if (whiteCount > minCount) {
            whiteStatus = "stopped";
          }
        } while (whiteStatus === "advanced");
        if (whiteStatus === "broken") {
          brokenList.push({
            target,
            count: whiteCount,
            type: "black",
            reason: "contradiction",
          });
          if (whiteCount === 1) {
            minCountFlag = true;
            break;
          }
          if (whiteCount < minCount) {
            minCount = whiteCount;
          }
        }

        const commonBlack = [...answerMethodsBlack.newBlacks].filter((e) =>
          answerMethodsWhite.newBlacks.has(e)
        );
        if (commonBlack.length > 0) {
          minCount = Math.min(minCount, Math.max(blackCount, whiteCount));
          brokenList.push({
            target: toPos(commonBlack[0]),
            count: Math.max(blackCount, whiteCount),
            type: "black",
            reason: "any-case",
            reasonPos: target,
          });
        }
        const commonWhite = [...answerMethodsBlack.newWhites].filter((e) =>
          answerMethodsWhite.newWhites.has(e)
        );
        if (commonWhite.length > 0) {
          minCount = Math.min(minCount, Math.max(blackCount, whiteCount));
          brokenList.push({
            target: toPos(commonWhite[0]),
            count: Math.max(blackCount, whiteCount),
            type: "white",
            reason: "any-case",
            reasonPos: target,
          });
        }
      }
    }

    // horizontal bar
    for (let y = 0; y < board.rows; y++) {
      for (let x = 0; x < board.cols - 1; x++) {
        const target = { x, y };
        if (
          (answer.horizontal.get(toId(target)) ?? -1) !== Model.LineState.None
        ) {
          continue;
        }
        if (answerMethods.forbidden(target)) {
          continue;
        }
        if (answerMethods.forbidden({ x: x + 1, y })) {
          continue;
        }

        const answerLine = Model.clone(answer);
        const answerMethodsLine = createAnswerMethods(board, answerLine, false);
        Model.addLine(answerLine, [target, { x: x + 1, y }]);
        let lineStatus = "advanced";
        let lineCount = 0;
        do {
          lineStatus = solve(answerMethodsLine);
          ++lineCount;
          if (lineCount > minCount) {
            lineStatus = "stopped";
          }
        } while (lineStatus === "advanced");
        if (lineStatus === "broken") {
          brokenList.push({
            line: [target, { x: x + 1, y }],
            count: lineCount,
            type: "bar",
            reason: "contradiction",
          });
          if (lineCount === 1) {
            minCountFlag = true;
            minCount = lineCount;
            break;
          }
          if (lineCount < minCount) {
            minCount = lineCount;
          }
        }

        const answerBar = Model.clone(answer);
        const answerMethodsBar = createAnswerMethods(board, answerBar, false);
        Model.setLineBar(answerBar, target, { x: x + 1, y });
        let barStatus = "advanced";
        let barCount = 0;
        do {
          barStatus = solve(answerMethodsBar);
          ++barCount;
          if (barCount > minCount) {
            barStatus = "stopped";
          }
        } while (barStatus === "advanced");
        if (barStatus === "broken") {
          brokenList.push({
            line: [target, { x: x + 1, y }],
            count: barCount,
            type: "line",
            reason: "contradiction",
          });
          if (barCount === 1) {
            minCountFlag = true;
            break;
          }
          if (barCount < minCount) {
            minCount = barCount;
          }
        }

        const commonBlack = [...answerMethodsLine.newBlacks].filter((e) =>
          answerMethodsBar.newBlacks.has(e)
        );
        if (commonBlack.length > 0) {
          minCount = Math.min(minCount, Math.max(lineCount, barCount));
          brokenList.push({
            target: toPos(commonBlack[0]),
            count: Math.max(lineCount, barCount),
            type: "black",
            reason: "any-case",
            reasonPos: target,
          });
        }
        const commonWhite = [...answerMethodsLine.newWhites].filter((e) =>
          answerMethodsBar.newWhites.has(e)
        );
        if (commonWhite.length > 0) {
          minCount = Math.min(minCount, Math.max(lineCount, barCount));
          brokenList.push({
            target: toPos(commonWhite[0]),
            count: Math.max(lineCount, barCount),
            type: "white",
            reason: "any-case",
            reasonPos: target,
          });
        }
      }
    }

    // vertical bar
    for (let y = 0; y < board.rows - 1; y++) {
      for (let x = 0; x < board.cols; x++) {
        const target = { x, y };
        if (
          (answer.vertical.get(toId(target)) ?? -1) !== Model.LineState.None
        ) {
          continue;
        }
        if (answerMethods.forbidden(target)) {
          continue;
        }
        if (answerMethods.forbidden({ x, y: y + 1 })) {
          continue;
        }
        const answerLine = Model.clone(answer);
        const answerMethodsLine = createAnswerMethods(board, answerLine, false);
        Model.addLine(answerLine, [target, { x, y: y + 1 }]);
        let lineStatus = "advanced";
        let lineCount = 0;
        do {
          lineStatus = solve(answerMethodsLine);
          ++lineCount;
          if (lineCount >= minCount) {
            lineStatus = "stopped";
          }
        } while (lineStatus === "advanced");
        if (lineStatus === "broken") {
          brokenList.push({
            line: [target, { x: x, y: y + 1 }],
            count: lineCount,
            type: "bar",
            reason: "contradiction",
          });
          if (lineCount === 1) {
            minCountFlag = true;
            break;
          }
          if (lineCount < minCount) {
            minCount = lineCount;
          }
        }
        const answerBar = Model.clone(answer);
        const answerMethodsBar = createAnswerMethods(board, answerBar, false);
        Model.setLineBar(answerBar, target, { x, y: y + 1 });
        let barStatus = "advanced";
        let barCount = 0;
        do {
          barStatus = solve(answerMethodsBar);
          ++barCount;
          if (barCount >= minCount) {
            barStatus = "stopped";
          }
        } while (barStatus === "advanced");
        if (barStatus === "broken") {
          brokenList.push({
            line: [target, { x, y: y + 1 }],
            count: barCount,
            type: "line",
            reason: "contradiction",
          });
          if (barCount === 1) {
            minCountFlag = true;
            break;
          }
          if (barCount < minCount) {
            minCount = barCount;
          }
        }
      }
    }

    if (brokenList.length > 0) {
      brokenList.sort((a, b) => a.count - b.count);
      /*(tryLog ? [brokenList[0]] : brokenList)*/

      [brokenList[0]].forEach((broken) => {
        if (broken.type === "white") {
          let reason = "";
          if (tryLog) {
            if (broken.reason === "contradiction") {
              reason =
                `(${broken.target.x + 1},${broken.target.y + 1})` +
                "に白マスを配置\n" +
                `黒マス仮置き ${broken.count}手`;
            } else if (broken.reason === "any-case") {
              reason =
                `(${broken.target.x + 1},${
                  broken.target.y + 1
                })に白マスを配置\n` +
                `(${broken.reasonPos.x + 1},${
                  broken.reasonPos.y + 1
                })マスをいずれの色にしても確定`;
            }
            if (broken.reason === "any-case") {
              console.log(
                `いずれにせよ白${broken.reasonPos.x + 1},${
                  broken.reasonPos.y + 1
                }`
              );
            }
          }
          answerMethods.addWhiteCell(answerMethods, broken.target, reason);
        } else if (broken.type === "black") {
          let reason = "";
          if (tryLog) {
            if (broken.reason === "contradiction") {
              reason =
                `(${broken.target.x + 1},${broken.target.y + 1})` +
                "に黒マスを配置\n" +
                `白マス仮置き ${broken.count}手`;
            } else if (broken.reason === "any-case") {
              reason =
                `(${broken.target.x + 1},${
                  broken.target.y + 1
                })に黒マスを配置\n` +
                `(${broken.reasonPos.x + 1},${
                  broken.reasonPos.y + 1
                })マスをいずれの色にしても確定`;
            }
            if (broken.reason === "any-case") {
              console.log(
                `いずれにせよ黒${broken.reasonPos.x + 1},${
                  broken.reasonPos.y + 1
                }`
              );
            }
          }

          answerMethods.addBlackCell(answerMethods, broken.target, reason);
        } else if (broken.type === "line") {
          if (tryLog) {
            console.log(
              `(${broken.line[0].x + 1},${broken.line[0].y + 1})` +
                "と" +
                `(${broken.line[1].x + 1},${broken.line[1].y + 1})` +
                "の間に線が通る",
              `線先読み ${broken.count}手`
            );
          }
          Model.addLine(answer, broken.line);
        } else if (broken.type === "bar") {
          if (tryLog) {
            console.log(
              `(${broken.line[0].x + 1},${broken.line[0].y + 1})` +
                "と" +
                `(${broken.line[1].x + 1},${broken.line[1].y + 1})` +
                "の間には線が通らない",
              `線先読み ${broken.count}手`
            );
          }
          Model.setLineBar(answer, broken.line[0], broken.line[1]);
        }
      });
    } else {
      return "stopped";
    }
    return "advanced";
  };

  const run = (a?: Model.AnswerModel, _methodConfig?: MethodConfig) => {
    const answer = a ? a : answers.getAnswer();
    const methodConfig = _methodConfig ? _methodConfig : defaultMethodConfig;
    const answerMethods = createAnswerMethods(board, answer, !a);
    if (
      Model.isSingleLine(answer) &&
      answer.edge.size === 0 &&
      answer.innerLine.size > 0
    ) {
      solve(answerMethods);
      if (!a) {
        answers.setAnswer(answer);
      }
      return "stopped";
    }
    const status = solve(answerMethods);

    if (status === "advanced") {
      if (!a) {
        answers.setAnswer(answer);
      }
      return status;
    }

    // console.log(Model.isSingleLine(answer));
    const tryLog = !a;
    if (
      status === "stopped" &&
      !Model.isSingleLine(answer) &&
      methodConfig.trySolve
    ) {
      const tryStatus = trySolve(answer, answerMethods, tryLog);
      if (tryStatus === "stopped") {
        return "stopped";
      } else {
        if (!a) {
          answers.setAnswer(answer);
        }
        return tryStatus;
      }
    }
    return status;
  };

  const runAll = (id: string) => {
    const start = new Date();
    let status = "advanced";
    const answer = answers.getAnswer();

    const method = {
      trySolve: true,
    };

    while (status === "advanced") {
      status = run(answer, method);
    }
    answers.setAnswer(answer);
    const end = new Date();
    const time = end.getTime() - start.getTime();
    //setTimeList((prev) => {
    //  return [...prev, time];
    // });

    // console.log(id, time);
    if (
      (false && parseInt(id ?? "-1") % 100 === 0) ||
      parseInt(id ?? "-1") === 1308
    ) {
      console.log(
        "all",
        timeList.reduce((a, b) => a + b)
      );
      console.log(
        "max",
        timeList.reduce((a, b) => Math.max(a, b))
      );
      // console.log("solveCount", solveCount);
      // console.log(timeList.join("\n"));
    }

    if (status === "stopped") {
      status =
        Model.isSingleLine(answer) &&
        answer.edge.size === 0 &&
        answer.innerLine.size > 0
          ? "completed"
          : "stopped";
    }
    return {
      status,
      score:
        status === "broken"
          ? 0
          : answer.innerLine.size + answer.blackCells.size,
    };
  };
  return { run, runAll };
};
