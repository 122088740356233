import { Board, toId, toPos } from "../../../board";
import { Position } from "../../../Position";
import { AnswerMethods } from "../createAnswerMethods";

export type ConnectionMap = {
  map: Map<number, number>;
  cells: Position[][];
  areaCount: number;
};
export const createConnectionMap = (board: Board, answer: AnswerMethods) => {
  const map = new Map<number, number>();
  let areaCount = 0;

  const dfs = (pos: Position, id: number) => {
    map.set(toId(pos), id);
    answer.edgeAround(pos).forEach(({ pos: pos2 }) => {
      if (answer.isLineEdge(pos2)) {
        return;
      }
      if (answer.forbidden(pos2)) {
        return;
      }
      if (map.has(toId(pos2))) {
        return;
      }
      dfs(pos2, id);
    });
  };

  for (let i = 0; i < board.cell.length; ++i) {
    const pos = { x: i % board.cols, y: Math.floor(i / board.cols) };
    const forbidden = (pos: Position) =>
      answer.forbidden(pos) || answer.isLineEdge(pos) || map.has(toId(pos));
    if (forbidden(pos)) {
      continue;
    }
    dfs(pos, areaCount);
    ++areaCount;
  }
  const cells: Position[][] = [...new Array(areaCount)].map(() => []);
  map.forEach((id, key) => {
    cells[id].push(toPos(key));
  });

  return {
    map,
    cells,
    areaCount,
  };
};
