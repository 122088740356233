import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { data, ProblemData } from "../data/data";
import { decodeArrowNumber16 } from "../lib/decode";
import { encodeArrowNumber16 } from "../lib/encode";
import { Board, createBoard } from "../puzzle/board";
import { BoardController } from "../puzzle/gridkit/BoardController";
import { generateYajilinBoard } from "../puzzle/yajilin/generator/generateYajilinBoard";
import { useYajilinAnswer } from "../puzzle/yajilin/useYajilinAnswer";
import { useYajilinSolver } from "../puzzle/yajilin/useYajilinSolver";

type Props = {};

export const Yajilin = () => {
  const location = useLocation();
  const arr = location.search.substring(1).split("/");
  const [puzzleType, colString, rowString, _boardCode] =
    arr.length === 5 ? arr.filter((e, i) => i !== 1) : arr;
  const _col = parseInt(colString);
  const _row = parseInt(rowString);
  const [board, setBoard] = useState<Board>(createBoard(_col, _row));
  const [boardCode, setBoardCode] = useState(_boardCode ?? "");
  boardCode !== "" && decodeArrowNumber16(boardCode ?? "", board);

  const [redraw, setRedraw] = useState(0);
  const [currentProblem, setCurrentProblem] = useState(0);
  const [solvedCount, setSolvedCount] = useState(0);
  const [unSolvedCount, setUnSolvedCount] = useState(0);
  const [problem, setProblem] = useState<ProblemData | null>(null);
  const answers = useYajilinAnswer();
  const solver = useYajilinSolver(board, answers);
  const [time, updateTime] = useState(Date.now());
  const [score, setScore] = useState(0);

  useEffect(() => {
    if (puzzleType !== "yajilinAuto" && puzzleType !== "yajilinGenerator") {
      return;
    }
    if (solvedCount === -1) {
      return;
    }
    if (!problem) {
      return;
    }
    const { status, score } = solver.runAll(problem.index);
    let timeoutId: NodeJS.Timeout | null = null;
    setScore(score);
    timeoutId = setTimeout(() => updateTime(Date.now()), 3);
    setRedraw((prev) => prev + 1);
    if (status === "completed") {
      if (puzzleType === "yajilinGenerator") {
        console.log(
          "solved",
          encodeArrowNumber16("https://puzz.link/p?yajilin/10/10/", board)
        );
      }
      setSolvedCount((prev) => prev + 1);
      setCurrentProblem((prev) => prev + 1);
    } else if (status === "stopped") {
      if (puzzleType === "yajilinGenerator") {
        // console.log("unsolved score:", score);
      } else {
        console.log("unsolved id:", problem.index);
      }
      setUnSolvedCount((prev) => prev + 1);
      setCurrentProblem((prev) => prev + 1);
    } else if (status === "broken") {
      if (puzzleType === "yajilinGenerator") {
        // console.log("broken score:", score);
      } else {
        console.log("broken id:", problem.index);
      }
      setUnSolvedCount((prev) => prev + 1);
      setCurrentProblem((prev) => prev + 1);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [time, solvedCount, problem]);

  useEffect(() => {
    if (puzzleType !== "yajilinAuto" && puzzleType !== "yajilinGenerator") {
      return;
    }
    board.cols = 10;
    board.rows = 10;

    const problem =
      puzzleType === "yajilinAuto" ? data[currentProblem] : data[0];
    setProblem(problem ?? null);
    if (solvedCount === -1) {
      return;
    }
    if (!problem) {
      console.log("finished");
      console.log("solvedCount", solvedCount);
      console.log("unSolvedCount", unSolvedCount);
      setSolvedCount(-1);
      return;
    }

    const arr = problem.code.split("/");
    const [_, colString, rowString, _boardCode] =
      arr.length === 5 ? arr.filter((e, i) => i !== 1) : arr;
    if (puzzleType === "yajilinAuto") {
      const _board = createBoard(parseInt(colString), parseInt(rowString));
      setBoardCode(_boardCode);
      decodeArrowNumber16(_boardCode ?? "", _board);
      setBoard(_board);
    }
    if (puzzleType === "yajilinGenerator") {
      setBoard(
        generateYajilinBoard(board, score, {
          minNumber: 0,
          maxNumber: 1,
          numbers: [0, 4],
          clueCount: 8,
          initialNumber: 10,
          maxTry: 100,
          symmetry: true,
          moveRate: 0.7,
        })
      );
    }
    answers.clear();
    setRedraw(1000);
    updateTime(Date.now());
  }, [currentProblem]);

  if (
    (puzzleType === "yajilinAuto" || puzzleType === "yajilinGenerator") &&
    redraw < 1000
  ) {
    return <></>;
  }

  return (
    <BoardController
      board={board}
      answers={answers}
      solver={solver}
      setRedraw={setRedraw}
    />
  );
};
